 const RoutesNames = {
   HOME: "/home",
   EVENT: "/event",
   SPORT_FACILITIES: "/sport-facilities",
   PERSONAL_COACHES: "/personal-coaches",
   GROUP_ACTIVITIES: "/group-activities",
   EVENTS: "/event-activities",
   ABOUT_US: "/about-us",
   SIGN_IN: "/authentication/sign-in",
   SIGNUP: "/authentication/sign-up",
   NEW_BUSINESS: "/business/new",
   EDIT_BUSINESS: "/business/edit",
   BUSINESS_PROFILE: "/business/profile",
   BUSINESS_DASHBOARD: "/business/dashboard",
   BUSINESS_RESERVATIONS: "/business/reservations",
   BUSINESS_LIST: "/business/list",
   ADMIN_CRM: "/admin/crm",
   ADMIN_ACTIVE_USERS_TABLE: "/admin/users/active",
   ADMIN_BLOCKED_USERS_TABLE: "/admin/users/blocked",
   ADMIN_DELETED_USERS_TABLE: "/admin/users/deleted",
   ADMIN_BUSINESS_TABLE_BY_TYPE: "/admin/businesses-table/:type",
   BUSINESS_REQUESTS_TABLE: "/admin/businesses/active-requests",
   BUSINESSES_ARCHIVE: "admin/businesses/archive",
   ACTIVE_BUSINESSES_TABLE: "/admin/businesses/active",
   BUSINESS_SETTINGS: "/admin/business/settings",
   USER_SETTINGS: "/admin/user/settings",
   CLIENT_PROFILE: "/client/profile",
   CLIENT_DASHBOARD: "/client/dashboard",
   CLIENT_RESERVATIONS: "/client/reservations",
   ACCOUNT_ACTIVATION: "/account/activation/:confirmationCode",
   PASSWORD_RESET_INIT: "/account/password-reset/init",
   PASSWORD_RESET_CONFIRM: "/account/password-reset/confirm/:userId/:token",
   SEARCH: "/search"
}

export default RoutesNames;