/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const useFormFields = (t) => {
  return {
    newPassword: {
      name: "newPassword",
      label: t('auth.resetPasswordConfirm.newPass'),
      type: "password",
      errorMsg: t('auth.resetPasswordConfirm.newPassRequired'),
      invalidMsg: t('auth.resetPasswordConfirm.newPassInvalid'),  /* TODO */
    },
    repeatPassword: {
      name: "repeatPassword",
      label: t('auth.resetPasswordConfirm.confirmNewPass'),
      type: "password",
      errorMsg: t('auth.resetPasswordConfirm.confirmNewPassRequired'), 
      invalidMsg: t('auth.resetPasswordConfirm.confirmNewPassInvalid'), 
    },
  };
}

export default useFormFields;
