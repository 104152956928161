/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { fields } from "layouts/pages/business/new-business/schemas/form";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum"
import { countryOptions } from "../../business-form-helpers";

const {
  [FormNames.CHOOSE_BUSINESS_TYPE]: {
    type
  },
  [FormNames.BUSINESS_INFO_FORM]: {
    name, description, eventDateStart, eventDateFinish, yearOfBirth, aboutMe, aboutPrograms
  },
  [FormNames.PROGRAM_DETAILS_FORM]: {
    ageCategory, gender, skillLevel, sport, numberOfFreeTrainings, workingHours, schedule
  },
  [FormNames.ADDRESS_FORM]: {
    addresses
  },
  [FormNames.SPORT_FACILITY_ADDITIONAL_FORM]: {
    additional: additional
  },
  [FormNames.MEDIA_FORM]: {
    mainImage, coverImage, galleryImages
  },
  [FormNames.CONTACT_DATA_FORM]: {
    website, email, contactPhone,
    instagramAccount, facebookAccount, twitterAccount, tiktokAccount
  },
  [FormNames.PRICING_FORM]: {
    pricingList
  },
} = fields;


function getInitialValues() {
  return {
    [FormNames.CHOOSE_BUSINESS_TYPE]: {
      [type.name]: null,
    },
    [FormNames.BUSINESS_INFO_FORM]: {
      [name.name]: "",
      [description.name]: "",
      [eventDateStart.name]: "",
      [eventDateFinish.name]: "",
      [yearOfBirth.name]: 1900,
      [aboutMe.name]: "",
      [aboutPrograms.name]: "",
    },
    [FormNames.PROGRAM_DETAILS_FORM]: {
      [ageCategory.name]: [],
      [gender.name]: gender.default,
      [skillLevel.name]: [],
      [sport.name]: [],
      [numberOfFreeTrainings.name]: 0,
      [workingHours.name]: [{
        day: workingHours.props.day.default,
        startTime: "",
        endTime: ""
      }],
      [schedule.name]: [{
        description: "",
        startTime: "",
        endTime: ""
      }],
    },
    [FormNames.ADDRESS_FORM]: {
      [addresses.name]: [{
        addressLine: "",
        addressNumber: "",
        city: "",
        country: countryOptions[0].label, // [CHANGE COUNTRY TO LIST] countryOptions[0]
        zipCode: "",
      }],
    },
    [FormNames.SPORT_FACILITY_ADDITIONAL_FORM]: {
      [additional.name]: []
    },
    [FormNames.MEDIA_FORM]: {
      [mainImage.name]: null,
      [coverImage.name]: null,
      [galleryImages.name]: [],
    },
    [FormNames.CONTACT_DATA_FORM]: {
      [website.name]: "",
      [email.name]: "",
      [contactPhone.name]: "",
      [instagramAccount.name]: "",
      [facebookAccount.name]: "",
      [twitterAccount.name]: "",
      [tiktokAccount.name]: "",
    },
    [FormNames.PRICING_FORM]: {
      [pricingList.name]: [{
        description: "",
        price: 0,
        currency: pricingList.props.currency.default,
        discount: 5,
        discountedPrice: 0
      }]
    },
  };
}


let initial = getInitialValues();

const setPopulatedValues = (populatedData, formName) => {
  if (formName == FormNames.MEDIA_FORM) {
    initial[formName] = populatedData.mediaPayload;
    return
  }
  initial[formName] = populatedData;
}

const clearInitialValues = () => {
  initial = getInitialValues();
}

const getInitialObj = (populatedData, formName) => {
  return initial
}

export {
  initial,
  setPopulatedValues,
  clearInitialValues,
  getInitialObj
};
