/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";

import PageLayout from "examples/LayoutContainers/PageLayout";
import HeaderMenu from "components/common/HeaderMenu";

import { Formik, Form } from "formik";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";

import { useEffect, useRef, useState } from "react";
import { initialValues } from "layouts/pages/business/edit-business/schemas/initialValues";
import { populateInitialValues } from "layouts/pages/business/edit-business/schemas/initialValues";
import { validations } from "layouts/pages/business/edit-business/schemas/validations";
import { formFields } from "layouts/pages/business/edit-business/schemas/form";

import { Card, IconButton, Tooltip } from "@mui/material";
import BusinessInfoEdit from "./components/BusinessInfoEdit";
import ContactDataEdit from "./components/ContactDataEdit";
import SportFacilityAdditionalEdit from "./components/SportFacilityAdditionalEdit";
import PricingEdit from "./components/PricingEdit";
import ProgramDetailsEdit from "./components/ProgramDetailsEdit";
import AddressEdit from "./components/AddressEdit";
import WorkingHoursAndScheduleEdit from "./components/WorkingHoursAndScheduleEdit";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import ImageEdit from "components/common/ImageEdit";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { useLocation } from "react-router-dom";
import loader from "assets/images/moveon/loader-simple.svg";
import AdminFooter from "examples/Footer";
import { setSelectedType } from "./schemas/validations";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useTranslation } from "react-i18next";
import { CreateMultipartPostRequest } from "helpers/back-request/helpers";
import { Buffer } from 'buffer';
import { getCountryValue } from "../business-form-helpers";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import DeleteIcon from '@mui/icons-material/Delete';

function EditBusiness({ businessItemId }) {
    const { t, i18n } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const location = useLocation();
    const [businessDataObj, setBusinessDataObj] = useState();
    const [initialValuesData, setInitialValuesData] = useState(initialValues);
    const [isAddressBoxFullWidth, setIsAddressBoxFullWidth] = useState(false);
    const [showMainImageError, setShowMainImageError] = useState(false);
    const [images, setImages] = useState(
            [
            // {
            //     original: "https://picsum.photos/id/1015/1000/600/",
            //     thumbnail: "https://picsum.photos/id/1015/1000/600/",
            // },
            // {
            //     original: "https://picsum.photos/id/1015/1000/600/",
            //     thumbnail: "https://picsum.photos/id/1015/1000/600/",
            // },
            // {
            //     original: "https://picsum.photos/id/1019/1000/600/",
            //     thumbnail: "https://picsum.photos/id/1019/1000/600/",
            // },
        ]
    );

    const formikRef = useRef(null);

    const getBusinessData = async () => {
        try {
            console.log("getBusinessData")
            // Get business data from database
            const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_BY_ID + location.state.businessItemId);
            const businessData = response.data.data;
            setBusinessDataObj(businessData);
            setSelectedType(businessData.type);

            let mainImage = null;
            if (businessData.mediaPayload?.mainImage) {
                const mainImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessData.mediaPayload?.mainImage, { responseType: 'blob' });
                mainImage = URL.createObjectURL(mainImageResponse.data);
            }

            let coverImage = null;
            if (businessData.mediaPayload?.coverImage) {
                const coverImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessData.mediaPayload?.coverImage, { responseType: 'blob' });
                coverImage = URL.createObjectURL(coverImageResponse.data);
            }

            let galleryImages = [];
            if (businessData.mediaPayload?.gallery) {
                for (let i = 0; i < businessData.mediaPayload.gallery.length; i++) {
                    const galleryImageId = businessData.mediaPayload.gallery[i];
                    if (galleryImageId == null) {
                        continue
                    }
                    const galleryImagesResponse = await CreateGetRequest(BackRequests.GET_MEDIA + galleryImageId, { responseType: 'blob' });
                    galleryImages.push(URL.createObjectURL(galleryImagesResponse.data))
                }
                console.log(galleryImages)
                setImages(galleryImages.map(img => {
                    return {
                        original: img
                    }
                }));
            }

            return {
                ...populateInitialValues(businessData),
                mediaImages: {
                    mainImage,
                    coverImage,
                    galleryImages
                }
            };
        } catch (error) {
            console.log("error")
            console.log(error)
            return initialValuesData;
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setShowLoader(true);
            const businessData = await getBusinessData();
            console.log(businessData)

            setInitialValuesData(businessData);
            if (businessData.type != BusinessItemTypes.SPORT_FACILITY
                && businessData.type != BusinessItemTypes.GROUP_ACTIVITY) {
                setIsAddressBoxFullWidth(true);
            }
            setShowLoader(false);
        }
        loadData();
    }, []);

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSB, setErrorSB] = useState(false);

    const openErrorSB = (message) => {
        setErrorMessage(message);
        setErrorSB(true);
    };
    const closeErrorSB = () => setErrorSB(false);

    // Success messages
    const [successMessage, setSuccessMessage] = useState("");
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = (message) => {
        setSuccessMessage(message);
        setSuccessSB(true);
    }
    const closeSuccessSB = () => setSuccessSB(false);

    const inputRef = useRef();

    const onChooseImg = (event) => {
        inputRef.current.click();
    }

    const handleAddImage = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            console.log("handleAddImage")
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (e) {
                images.push({
                    original: reader.result
                });
                setImages(images);
            }
        }
    }

    const handleSubmit = async (values, actions) => {
        console.log("EDIT BUSINESS")
        console.log(values);
        try {
            let mainImageId = businessDataObj.mediaPayload?.mainImage;
            if (values.mainImage != initialValuesData.mediaImages?.mainImage) {
                const imageBase64 = values.mainImage.split(',')[1];
                const body = new FormData();
                const buff = Buffer.from(imageBase64, "base64");
                body.append('file', new Blob([buff], { type: `image/jpeg` }));
                const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
                mainImageId = response?.data?.data?.fileName;
            }

            let coverImageId = businessDataObj.mediaPayload?.coverImage;
            if (values.coverImage != initialValuesData.mediaImages.coverImage) {
                if (values.coverImage == "") {
                    coverImageId = null;
                } else {
                    const imageBase64 = values.coverImage.split(',')[1];
                    const body = new FormData();
                    const buff = Buffer.from(imageBase64, "base64");
                    body.append('file', new Blob([buff], { type: `image/jpeg` }));
                    const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
                    coverImageId = response?.data?.data?.fileName;
                }
            }

            const businessItemPayload = {
                id: location.state.businessItemId,
                type: businessDataObj.type,
                name: values.name,
                description: values.description,
                ageCategories: values.ageCategory.map((ageCategory) => { return ageCategory.value }),
                additional: values.additional.map((additional) => { return additional.value }),
                mediaPayload: {
                    mainImage: mainImageId,
                    coverImage: coverImageId,
                    gallery: businessDataObj.mediaPayload.gallery ?? [],
                    id: businessDataObj?.mediaPayload?.id,
                },
                addresses: values.addresses?.map((addr) => {
                    return {
                        addressLine: addr.addressLine,
                        addressNumber: addr.addressNumber,
                        city: addr.city,
                        country: getCountryValue(addr.country),
                        zipCode: addr.zipCode,
                    }
                }),
                gender: values.gender.value,
                skillLevels: values.skillLevel.map((skillLevel) => { return skillLevel.value }),
                sports: values.sport.map((sport) => { return sport.value }),
                pricelist: values.pricingList,
                contactData: {
                    id: businessDataObj?.contactData?.id,
                    ... (values.website && { website: values.website }),
                    ... (values.email && { email: values.email }),
                    ... (values.contactPhone && { contactPhone: values.contactPhone }),
                },
                socialNetworkAccounts: {
                    id: businessDataObj?.socialAccounts?.id,
                    instagramAccount: values.instagramAccount,
                    twitterAccount: values.twitterAccount,
                    facebookAccount: values.facebookAccount,
                    tiktokAccount: values.tiktokAccount,
                }
            }

            switch (initialValuesData?.type) {
                case BusinessItemTypes.EVENT:
                    businessItemPayload.eventPayload = {
                        id: businessDataObj?.eventPayload?.id,
                        dateStart: values.eventDateStart,
                        dateFinish: values.eventDateFinish,
                    }
                    break;
                case BusinessItemTypes.PERSONAL_COACH:
                    businessItemPayload.personalCoachPayload = {
                        id: businessDataObj?.personalCoachPayload?.id,
                        numberOfFreeTrainings: values.numberOfFreeTrainings,
                        yearOfBirth: values.yearOfBirth,
                        aboutMe: values.aboutMe,
                    }
                    break;
                case BusinessItemTypes.SPORT_FACILITY:
                    businessItemPayload.sportFacilityPayload = {
                        id: businessDataObj?.sportFacilityPayload?.id,
                        numberOfFreeTrainings: values.numberOfFreeTrainings,
                        workingHours: values.workingHours.map((workingHours) => {
                            const { day, ...other } = workingHours;
                            return {
                                day: day.value,
                                ...other
                            }
                        })
                    }
                    break;
                case BusinessItemTypes.GROUP_ACTIVITY:
                    businessItemPayload.groupActivityPayload = {
                        id: businessDataObj?.groupActivityPayload?.id,
                        schedule: values.schedule,
                        aboutPrograms: values.aboutPrograms,
                        numberOfFreeTrainings: values.numberOfFreeTrainings
                    }
                    break;
            }
            console.log(businessItemPayload)
            await CreatePostRequest(BackRequests.EDIT_BUSINESS_ITEM, businessItemPayload);
            openSuccessSB("Business data changed successfuly");
        } catch (error) {
            console.log(error)
            openErrorSB(GetErrorMessage(error));
            throw error;
        }
    };

    const selectImageAction = (image, type, values) => {
        if (type == "main") {
            values[formFields.mainImage.name] = image;
            setShowMainImageError(false);
            formikRef.current.validateForm();
        }
        if (type == "cover") {
            values[formFields.coverImage.name] = image;
        }
    }

    const onUpload = (fileId, fieldName, values, errors) => {
        if (fieldName == formFields.galleryImages.name) {
            values[fieldName].push(fileId)
        } else {
            values[fieldName] = fileId;
        }
        delete errors[fieldName]
    }

    const deleteGalleryImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        console.log(updatedImages)
        setImages(updatedImages);
    };

    // Custom renderItem function for each gallery item
    const renderCustomItem = (item, index) => (
        <div>
            <img src={item.original} style={{ width: "60%" }} />
            <SoftBox textAlign="center" mt={1}>
                <Tooltip title={t('businessItem.editPage.removeImage')} placement="bottom" arrow>
                    <SoftBox>
                        <IconButton variant="gradient" color="primary" size="medium"
                            onClick={() => deleteGalleryImage(images.indexOf(item))}>
                            <DeleteIcon sx={{ fontWeight: "bold" }} fontSize="medium">{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>                        </IconButton>
                    </SoftBox>
                </Tooltip>
            </SoftBox>
        </div>
    );

    const getEditElements = () => {
        return (<SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",
        })}>
            {!showLoader &&

                <SoftBox>
                    <Formik
                        innerRef={formikRef}
                        enableReinitialize
                        initialValues={initialValuesData}
                        validationSchema={validations}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue, handleChange }) => (
                            <Form id="edit-business-data" autoComplete="off">
                                <SoftBox mb={3}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} >
                                            <Grid container spacing={3} alignItems="center">
                                                <Grid item xs={12} lg={6}>
                                                    <SoftTypography variant="h4" fontWeight="medium">
                                                        {t('businessItem.editPage.title')}
                                                    </SoftTypography>
                                                    <SoftBox mt={1} mb={2}>
                                                        <SoftTypography variant="body2" color="text">
                                                            {t('businessItem.editPage.description')}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <SoftBox display="flex" justifyContent="flex-end">
                                                        <SoftButton key="submit" color="secondary" type="submit" disabled={isSubmitting || Object.keys(errors).length != 0}>
                                                            {t('businessItem.editPage.button')}
                                                        </SoftButton>
                                                    </SoftBox>
                                                    <SoftBox display="flex" justifyContent="flex-end" mt={2}>
                                                        {Object.keys(errors).length != 0 && (
                                                            <SoftTypography variant="caption" color="error" align="right">
                                                                {t('businessItem.editPage.validationError')}
                                                            </SoftTypography>
                                                        )}
                                                        {console.log(errors)}
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        </SoftBox>

                                    </Card>
                                </SoftBox>
                                <SoftBox my={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={8}>
                                            <Card sx={{ height: "100%" }}>
                                                <BusinessInfoEdit setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} type={initialValuesData?.type} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%" }}>
                                                <ContactDataEdit values={values} errors={errors} touched={touched} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={isAddressBoxFullWidth ? 12 : 7}>
                                            <Card sx={{ height: "100%" }}>
                                                {initialValuesData?.type &&
                                                    <AddressEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} />
                                                }
                                            </Card>
                                        </Grid>
                                        {initialValuesData?.type && (initialValuesData?.type == BusinessItemTypes.SPORT_FACILITY || initialValuesData?.type == BusinessItemTypes.GROUP_ACTIVITY) && (
                                            <Grid item xs={12} lg={5}>
                                                <Card sx={{ height: "100%" }}>
                                                    <WorkingHoursAndScheduleEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} setFieldValue={setFieldValue} />
                                                </Card>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} lg={6}>
                                            <Card sx={{ height: "100%" }}>
                                                <ProgramDetailsEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} setFieldValue={setFieldValue} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Card sx={{ height: "100%" }}>
                                                {initialValuesData?.type &&
                                                    <SportFacilityAdditionalEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} setFieldValue={setFieldValue} />
                                                }
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Card sx={{ height: "100%" }}>
                                                <PricingEdit values={values} errors={errors} touched={touched} handleChange={handleChange} setFieldValue={setFieldValue} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%" }}>
                                                <ImageEdit initialImage={initialValuesData.mediaImages?.mainImage} type="main" showTitle selectImageAction={selectImageAction} values={values} showMainImageError={showMainImageError} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%" }}>
                                                <ImageEdit initialImage={initialValuesData.mediaImages?.coverImage} type="cover" showTitle selectImageAction={selectImageAction} values={values} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%", padding: "24px" }} p={3} >
                                                <SoftBox height="100%" display="grid">
                                                    <SoftBox mb={2} ml={0.5} lineHeight={0} display="inline-block">
                                                        <SoftTypography variant="h5" component="label">
                                                            {t('businessItem.editPage.gallery')}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    {images?.length > 0 &&
                                                        <SoftBox height="100%" display="flex" flexDirection="column">
                                                            <Gallery
                                                                items={images}
                                                                renderItem={(item, index) => renderCustomItem(item, index)} // Using renderItem to customize each image
                                                                showFullscreenButton={false}
                                                                showThumbnails={false}
                                                                autoPlay={false}
                                                                showPlayButton={false}
                                                                showBullets={false}
                                                            />
                                                             <input
                                                                type="file"
                                                                accept="image/*"
                                                                ref={inputRef}
                                                                onChange={handleAddImage}
                                                                style={{ display: "none" }}
                                                            />
                                                             <SoftBox style={{marginTop:"auto"}} width="100%" display="flex" justifyContent="end">
                                                                <Tooltip title="Add image" placement="bottom" arrow>
                                                                    <SoftButton color="secondary" size="small"
                                                                       onClick={onChooseImg}
                                                                    >
                                                                        + add
                                                                    </SoftButton>
                                                                </Tooltip>
                                                            </SoftBox>
                                                        </SoftBox>
                                                    }
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Form>
                        )}
                    </Formik>
                </SoftBox>
            }
            {showLoader &&
                <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
                    <img src={loader} alt="Loading..." className="loader" />
                </SoftBox>}
            {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
            {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
        </SoftBox>)
    }

    if (location.state.isAdmin) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                {getEditElements()}
                <AdminFooter />
            </DashboardLayout>
        )
    } else {
        return (
            <PageLayout>
                <SoftBox align="center">
                    <HeaderMenu />
                </SoftBox>
                {getEditElements()}
                <Footer />
            </PageLayout>
        )
    }
}

export default EditBusiness;
