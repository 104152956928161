/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

 import useFormFields from "layouts/authentication/reset-password-confirm/schemas/form";
import { useTransition } from "react";

const useInitialValues = (t) => {
  const {
    newPassword, repeatPassword
  } = useFormFields(t);

  return {
    [newPassword.name]: "",
    [repeatPassword.name]: "",
  };

};

export default useInitialValues;