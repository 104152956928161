const useFormFields = (t) => {
    return {
        formId: "sign-in-form",
        formField: {
            email: {
                name: "email",
                label: t('auth.login.email'),
                type: "email",
                errorMsg: t('auth.login.emailRequired'),
                invalidMsg: t('auth.login.emailInvalid'),
            },
            password: {
                name: "password",
                label: t('auth.login.password'),
                type: "password",
                errorMsg: t('auth.login.passwordRequired'),
                invalidMsg: t('auth.login.passwordInvalid'), /* TODO */
            },
        },
    };

};

export default useFormFields;
