/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the SoftEditor
import SoftEditorRoot from "components/SoftEditor/SoftEditorRoot";

function SoftEditor(props) {

  const modules = {
    toolbar: [
      [{header: [1, 2, 3, 4, false]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      [{ script: "sub" }, { script: "super" }],
      ['clean']
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "background",
    "code",
    "script",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  return (
    <SoftEditorRoot>
      <ReactQuill theme="snow" modules={modules} formats={formats} {...props} />
    </SoftEditorRoot>
  );
}

export default SoftEditor;
