/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React icons
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import curved0 from "assets/images/curved-images/curved2.jpg";
import personalCoachCover from "assets/images/curved-images/personalCoachCover.jpg";
import eventCover from "assets/images/curved-images/eventCover.jpg";
import sportFacilityCover from "assets/images/curved-images/sportFacilityCover.jpg";
import groupActivityCover from "assets/images/curved-images/groupActivityCover.jpg";

import { useTranslation } from "react-i18next";
import SoftTypography from "components/SoftTypography";
import HeaderMenu from "components/common/HeaderMenu";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import SimpleFilters from "../SimpleFilters";

function PublicHeader({ shouldShowSearchBox = true, type }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  return (
    <SoftBox position="relative" >
      <HeaderMenu />
      <SoftBox width="100%"
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="22.75rem"

        sx={{
          backgroundImage: `url(${type && 
            type==BusinessItemTypes.PERSONAL_COACH ? personalCoachCover 
            : (type==BusinessItemTypes.EVENT ? eventCover 
            : (type==BusinessItemTypes.SPORT_FACILITY ? sportFacilityCover 
            : (type==BusinessItemTypes.GROUP_ACTIVITY ? groupActivityCover : curved0)))})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
        }}
      >

        <SoftTypography variant="h2" color="white" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%) " }}>
          {t('home.bannerText')}
        </SoftTypography>
        {/* <div style={{ position: "absolute", color: "primary" }}>
          <h1> {t('home.bannerText')} </h1>
        </div> */}
      </SoftBox>

      {shouldShowSearchBox && 
        <SimpleFilters/>
      }
    </SoftBox>
  );
}

export default PublicHeader;
