/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

// @mui icons
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Data
// Images
import RoutesNames from "helpers/enums/routes-map.enum";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreateGetRequest } from "helpers/back-request/helpers";
import { Link } from "react-router-dom";
import HeaderMenu from "components/common/HeaderMenu";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import Sidenav from "../side-nav";
import BusinessItemCard from "examples/Cards/BusinessItemCard";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReservationsChart from "./components/ReservationsChart";
import { useTranslation } from "react-i18next";
import loader from "assets/images/moveon/loader-simple.svg";
import placeholderMainImage from "assets/images/moveon/placeholder-main-image.svg";


function BusinessProfileOverview() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [businessItems, setBusinessItems] = useState([]);
  const [reservationsByBusiness, setReservationsByBusiness] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);


  const getBusinessItems = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEMS_FOR_USER + localStorage.getItem("userId"));
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
      // openErrorSB(GetErrorMessage(error), true);
    }
  };

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const businessItemsArray = await getBusinessItems();
      if (businessItemsArray) {
        for (let i = 0; i < businessItemsArray.length; i++) {
          const businessItem = businessItemsArray[i];
          if(businessItem.mediaPayload?.mainImage) {
            const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessItem.mediaPayload?.mainImage, { responseType: 'blob' });
            businessItemsArray[i].image = URL.createObjectURL(imageResponse.data);
          } else {
            businessItemsArray[i].image = placeholderMainImage;
          }
        }
        setBusinessItems(businessItemsArray);
      }
      setShowLoader(false);
    })();
  }, []);

  return (
    <PageLayout>
      <SoftBox xs={12} md={12} lg={12} align="center">
        <HeaderMenu />
      </SoftBox>
      <SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
      })}>
        {!showLoader &&
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <Card>
                <SoftBox p={3}>
                  <SoftTypography variant="h5">Dashboard</SoftTypography>
                </SoftBox>
                <SoftBox p={2}>
                  <Grid container spacing={3}>
                    {businessItems && businessItems?.slice(0, 3).map((businessItem, index) => (
                      <Grid item xs={12} md={6} xl={3} key={index}>
                        <BusinessItemCard
                          id={businessItem.id}
                          image={businessItem.image}
                          name={businessItem.name}
                          status={businessItem.status}
                          type={businessItem.type}
                          showStatus
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} md={6} xl={3}>
                      <Link to={RoutesNames.BUSINESS_LIST}>
                        <PlaceholderCard title={{ variant: "h5", text: "See all" }} outlined icon={<MoreHorizIcon />} />
                      </Link>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}></Grid>
            {reservationsByBusiness &&
              (<Grid item xs={12} lg={4}>
                <Card>
                  <SoftBox p={3}>
                    <SoftTypography variant="h5">Number of reservations by business</SoftTypography>
                  </SoftBox>
                  <ReservationsChart reservationsByBusiness={reservationsByBusiness} />
                </Card>
              </Grid>

              )}
          </Grid>
        }
        {showLoader && 
        <SoftBox textAlign="center" sx={{margin:"auto", height:"100%"}}>
          <img src={loader} alt="Loading..." className="loader"/>
        </SoftBox>}
        {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
        {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default BusinessProfileOverview;
