/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

function Separator() {
  const { t, i18n } = useTranslation();

  return (
    <SoftBox position="relative" py={0.25}>
      <Divider/>
      <SoftBox
        bgColor="white"
        position="absolute"
        top="50%"
        left="50%"
        px={1.5}
        lineHeight={1}
        sx={{transform: "translate(-50%, -60%)"}}
      >
        <SoftTypography variant="button" fontWeight="medium" color="secondary">
          {t('general.separator')}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

export default Separator;
