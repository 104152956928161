import * as Yup from "yup";
import useFormFields from "layouts/authentication/sign-up/basic/schemas/form";

const useValidations = (t) => {
    const {
        formField: { firstName, lastName, email, password, repeatPassword },
    } = useFormFields(t);

    return Yup.object().shape({
        [firstName.name]: Yup.string().required(firstName.errorMsg),
        [lastName.name]: Yup.string().required(lastName.errorMsg),
        [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
        [password.name]: Yup.string().required(password.errorMsg).min(5, password.invalidMsg),
        [repeatPassword.name]: Yup.string()
            .required(repeatPassword.errorMsg)
            .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
        // TODO: What is the rule for the password
    });
};

export default useValidations;