/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

// @mui icons
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";

// Data
// Images
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreateGetRequest } from "helpers/back-request/helpers";
import HeaderMenu from "components/common/HeaderMenu";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import Sidenav from "../side-nav";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import moment from 'moment'

// Form
import { formFields } from "layouts/pages/business/business-reservations/schemas/form";
import { validations } from "layouts/pages/business/business-reservations/schemas/validations";
import FormField from "components/common/FormField";
import { GetErrorMessage } from "helpers/back-request/helpers";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import ReservationCard from "examples/Cards/ReservationCard";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ClearIcon from '@mui/icons-material/Clear';
import { CreatePostRequest } from "helpers/back-request/helpers";
import { ReservationStatus } from "helpers/enums/reservation.enum";
import { getInitialValues } from "./schemas/initialValues";
import loader from "assets/images/moveon/loader-simple.svg";
import placeholderMainImage from "assets/images/moveon/placeholder-main-image.svg";

function BusinessReservations() {
    const { t, i18n } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const [businessNamesOptions, setBusinessNamesOptions] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [moreFiltersOpened, setMoreFiltersOpened] = useState(false);
    const [searchParams, setSearchParams] = useState({});

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSB, setErrorSB] = useState(false);

    const openErrorSB = (message) => {
        setErrorMessage(message);
        setErrorSB(true);
    };
    const closeErrorSB = () => setErrorSB(false);

    // Success messages
    const [successMessage, setSuccessMessage] = useState("");
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = (message) => {
        setSuccessMessage(message);
        setSuccessSB(true);
    };
    const closeSuccessSB = () => setSuccessSB(false);

    const getBusinessNamesOptions = async () => {
        try {
            const response = await CreateGetRequest(BackRequests.GET_BUSINESS_NAMES_BY_OWNER + JSON.parse(localStorage.getItem("userId")));
            return response.data.data;
        } catch (error) {
            console.log("error")
            console.log(error)
            // TODO
        }
    };

    useEffect(() => {
        (async () => {
            setShowLoader(true);
            const businessNames = await getBusinessNamesOptions();
            setBusinessNamesOptions(businessNames.map((businessData) => { return { value: businessData.id, label: businessData.name } }));
            const activeReservations = await getReservations({ reservationStatus: { value: ReservationStatus.CREATED } });
            setReservations(activeReservations);
            setShowLoader(false);
        })();
    }, []);

    const getReservations = async (values) => {
        try {
            setSearchParams(values);
            const response = await CreatePostRequest(BackRequests.SEARCH_RESERVATIONS, {
                ownerId: JSON.parse(localStorage.getItem("userId")),
                ... (values.businessName && { businessItemId: values.businessName?.value }),
                ... (values.reservationId && { reservationId: values.reservationId }),
                ... (values.dateFrom && { dateFrom: values.dateFrom }),
                ... (values.dateTo && { dateTo: values.dateTo }),
                ... (values.reservationStatus && (
                    (values.reservationStatus instanceof Array) ? (values.reservationStatus.length > 0 && { status: values.reservationStatus?.map((statusItem) => statusItem.value) })
                        : { status: [values.reservationStatus.value] })),
                ... (values.name && { firstName: values.name }),
                ... (values.lastname && { lastName: values.lastname }),
                ... (values.email && { email: values.email }),
            });
            const reservations = response.data.data;

            for (let i = 0; i < reservations.length; i++) {
                if(reservations[i].businessItem?.mediaPayload?.mainImage) {
                    const reservationImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + reservations[i].businessItem.mediaPayload.mainImage, { responseType: 'blob' });
                    reservations[i].businessItem.mediaPayload.mainImageData = URL.createObjectURL(reservationImageResponse.data);
                } else {
                    reservations[i].businessItem.mediaPayload.mainImageData = placeholderMainImage;
                }
            }

            return reservations;
        } catch (error) {
            console.log("error")
            console.log(error)
            // TODO
        }
    };

    useEffect(() => {
        (async () => {
            setReservations(searchResult);
        })();
    }, [searchResult]);

    const handleSubmit = async (values, actions) => {
        try {
            setSearchResult(await getReservations(values))
        } catch (error) {
            console.log("usao u error")
            openErrorSB(GetErrorMessage(error));
        }
    };

    return (
        <PageLayout>
            <SoftBox xs={12} md={12} lg={12} align="center">
                <HeaderMenu />
            </SoftBox>
            <SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                p: 3,
                position: "relative",
            })}>
                {!showLoader &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                            <Sidenav />
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={3}>
                                <Grid item width="100%">
                                    <Card>
                                        <SoftBox p={3} pb={0}>
                                            <SoftTypography variant="h5">{t('businessProfile.businessReservations.title')}</SoftTypography>
                                        </SoftBox>
                                        <SoftBox p={3}>
                                            <SoftBox>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={getInitialValues(t)}
                                                    validationSchema={validations}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {({ values, errors, touched, setFieldValue, resetForm }) => (
                                                        <Form id="reset-password-form" autoComplete="off">
                                                            <Grid container columnSpacing={3} >
                                                                <Grid item xs={12} md={4} xl={4}>
                                                                    <FormField
                                                                        type={formFields.reservationId.type}
                                                                        label={t(formFields.reservationId.label)}
                                                                        name={formFields.reservationId.name}
                                                                        value={values.reservationId}
                                                                        placeholder={formFields.reservationId.placeholder}
                                                                        error={errors.reservationId && touched.reservationId}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={4} xl={4}>
                                                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                        <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                                                            {t(formFields.businessName.label)}
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                    <SoftSelect
                                                                        name={formFields.businessName.name}
                                                                        options={businessNamesOptions}
                                                                        value={values.businessName}
                                                                        onChange={(event) => {
                                                                            setFieldValue(formFields.businessName.name, event);
                                                                        }}
                                                                        error={(errors.businessName && touched.businessName) ? true : false}
                                                                        menuPortalTarget={document.querySelector('body')}
                                                                    />

                                                                </Grid>
                                                                <Grid item xs={12} md={1} xl={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                        <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold" textAlign="center">
                                                                            {t('businessProfile.businessReservations.moreFilters')}
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                    {moreFiltersOpened ? (
                                                                        <Tooltip title={t('businessProfile.businessReservations.closeMoreFilters')} placement="top" arrow>
                                                                            <IconButton size="large" pt={0} color="primary" onClick={() => setMoreFiltersOpened(false)}>
                                                                                <FilterAltOffIcon />
                                                                            </IconButton>
                                                                        </Tooltip>) :
                                                                        (<Tooltip title={t('businessProfile.businessReservations.openMoreFilters')} placement="top" arrow>
                                                                            <IconButton size="large" pt={0} color="primary" onClick={() => setMoreFiltersOpened(true)}>
                                                                                <FilterAltIcon />
                                                                            </IconButton>
                                                                        </Tooltip>)}
                                                                </Grid>
                                                                <Grid item xs={12} md={1} xl={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                        <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold" textAlign="center">
                                                                            {t('businessProfile.businessReservations.clearForm')}
                                                                        </SoftTypography>
                                                                    </SoftBox>
                                                                    <Tooltip title={t('businessProfile.businessReservations.clearForm')} placement="top" arrow>
                                                                        <IconButton size="large" pt={0} color="primary" type="reset" onClick={e => resetForm()}>
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={12} md={2} xl={2} display="flex" justifyContent="center" alignItems="center">
                                                                    <SoftButton
                                                                        type="submit"
                                                                        color="secondary"
                                                                    >
                                                                        {t('businessProfile.businessReservations.search')}
                                                                    </SoftButton>
                                                                </Grid>
                                                                {
                                                                    moreFiltersOpened && <Grid item xs={12} md={4} xl={4}>
                                                                        <FormField
                                                                            type={formFields.dateFrom.type}
                                                                            label={t(formFields.dateFrom.label)}
                                                                            name={formFields.dateFrom.name}
                                                                            value={values.dateFrom}
                                                                            placeholder={formFields.dateFrom.placeholder}
                                                                            error={errors.dateFrom && touched.dateFrom}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {
                                                                    moreFiltersOpened && <Grid item xs={12} md={4} xl={4}>
                                                                        <FormField
                                                                            type={formFields.dateTo.type}
                                                                            label={t(formFields.dateTo.label)}
                                                                            name={formFields.dateTo.name}
                                                                            value={values.dateTo}
                                                                            placeholder={formFields.dateTo.placeholder}
                                                                            error={errors.dateTo && touched.dateTo}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {
                                                                    moreFiltersOpened && <Grid item xs={12} md={4} xl={4}>
                                                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                                            <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                                                                {t(formFields.reservationStatus.label)}
                                                                            </SoftTypography>
                                                                        </SoftBox>
                                                                        <SoftSelect
                                                                            name={formFields.reservationStatus.name}
                                                                            options={formFields.reservationStatus.options.map((option) => { return { ...option, label: t(option.label) } })}
                                                                            defaultValue={formFields.reservationStatus.options[0]}
                                                                            isMulti
                                                                            value={values.reservationStatus}
                                                                            onChange={(event) => {
                                                                                setFieldValue(formFields.reservationStatus.name, event);
                                                                            }}
                                                                            error={(errors.reservationStatus && touched.reservationStatus) ? true : false}
                                                                            menuPortalTarget={document.querySelector('body')}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {
                                                                    moreFiltersOpened && <Grid item xs={12} md={4} xl={4}>
                                                                        <FormField
                                                                            type={formFields.name.type}
                                                                            label={t(formFields.name.label)}
                                                                            name={formFields.name.name}
                                                                            value={values.name}
                                                                            placeholder={formFields.name.placeholder}
                                                                            error={errors.name && touched.name}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {
                                                                    moreFiltersOpened && <Grid item xs={12} md={4} xl={4}>
                                                                        <FormField
                                                                            type={formFields.lastname.type}
                                                                            label={t(formFields.lastname.label)}
                                                                            name={formFields.lastname.name}
                                                                            value={values.lastname}
                                                                            placeholder={formFields.lastname.placeholder}
                                                                            error={errors.lastname && touched.lastname}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {
                                                                    moreFiltersOpened && <Grid item xs={12} md={4} xl={4}>
                                                                        <FormField
                                                                            type={formFields.email.type}
                                                                            label={t(formFields.email.label)}
                                                                            name={formFields.email.name}
                                                                            value={values.email}
                                                                            placeholder={formFields.email.placeholder}
                                                                            error={errors.email && touched.email}
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                <Grid item minWidth="100%">
                                    <Card>
                                        <SoftBox p={3}>
                                            <Grid container spacing={3}>
                                                {reservations?.length > 0 ? reservations?.map((reservation, key) => (
                                                    <Grid item sm={12} lg={3} width="100%" key={key}> <ReservationCard
                                                        id={reservation.id}
                                                        type={reservation.businessItem.type}
                                                        image={reservation.businessItem.mediaPayload.mainImageData}
                                                        title={reservation.businessItem.name}
                                                        dateTime={moment(reservation.creationTime).format("DD/MM/yyyy HH:mm")}
                                                        reservation={reservation.chosenItem}
                                                        userName={reservation.user.firstName}
                                                        userLastName={reservation.user.lastName}
                                                        userEmail={reservation.user.email}
                                                        showUserData
                                                        actionUse
                                                        disabled={reservation.status != ReservationStatus.CREATED}
                                                        action={
                                                            reservation.status == ReservationStatus.CREATED ? (
                                                                { type: "clickAction", color: "primary", label: t('reservations.card.use') }
                                                            ) : (
                                                                reservation.status == ReservationStatus.REALIZED ? (
                                                                    { type: "label", route: "/", color: "primary", label: t('reservations.card.used') }
                                                                ) : (
                                                                    { type: "label", route: "/", color: "secondary", label: t('reservations.card.canceled') }
                                                                )
                                                            )
                                                        }
                                                        updateInitialValues={() => handleSubmit(searchParams)}
                                                    /></Grid>
                                                )) : (
                                                    <Grid item sm={12} lg={12}>
                                                        <SoftTypography variant="body2" color="text" textAlign="center">{t('businessProfile.businessReservations.noData')}</SoftTypography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                            </Grid>
                            <SoftBox mb={3}>


                            </SoftBox>
                        </Grid>
                    </Grid>
                }
                {showLoader &&
                    <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
                        <img src={loader} alt="Loading..." className="loader" />
                    </SoftBox>}
                {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
                {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
            </SoftBox>
            <Footer />
        </PageLayout>
    );
}

export default BusinessReservations;
