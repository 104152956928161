/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * New business Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import { Buffer } from 'buffer';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";

// NewBusiness page components
import BusinessInfo from "layouts/pages/business/new-business/components/BusinessInfo";
import Address from "layouts/pages/business/new-business/components/Address";
import Media from "layouts/pages/business/new-business/components/Media";
import ContactData from "layouts/pages/business/new-business/components/ContactData";
import Pricing from "layouts/pages/business/new-business/components/Pricing";
import SportFacilityAdditional from "layouts/pages/business/new-business/components/SportFacilityAdditional";
import { setPopulatedValues } from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import SuccessStep from "./components/SuccessStep";
import { clearInitialValues, getInitialObj } from "./schemas/initialValues";
import ChooseType from "./components/ChooseType";
import ProgramDetails from "./components/ProgramDetails";
import { setSelectedType } from "./schemas/validations";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import BusinessDataPreview from "./components/BusinessDataPreview";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HeaderMenu from "components/common/HeaderMenu";
import { getCountryValue } from "../business-form-helpers";
import { CreateMultipartPostRequest } from "helpers/back-request/helpers";

const StepType = {
  ACTION: "action",
  INFO: "info",
}

function getStepsInfo(type, activeStep, isLastStep, handleNext, handleBack, businessName, populatedData, newBusinessId) {
  let steps = [
    {
      formName: FormNames.CHOOSE_BUSINESS_TYPE,
      stepName: "Business Type",
      type: StepType.ACTION,
      component: <ChooseType activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} type={type} />
    },
    {
      formName: FormNames.BUSINESS_INFO_FORM,
      stepName: "Business Info",
      type: StepType.ACTION,
      component: <BusinessInfo activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} type={type} />
    },
    {
      formName: FormNames.ADDRESS_FORM,
      stepName: "Address",
      type: StepType.ACTION,
      component: <Address activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} />
    },
    {
      formName: FormNames.CONTACT_DATA_FORM,
      stepName: "Contact data",
      type: StepType.ACTION,
      component: <ContactData activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} />
    },
    {
      formName: FormNames.PROGRAM_DETAILS,
      stepName: "Program details",
      type: StepType.ACTION,
      component: <ProgramDetails activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} type={type} />
    },
    {
      formName: FormNames.SPORT_FACILITY_ADDITIONAL_FORM,
      stepName: "Additional",
      type: StepType.ACTION,
      component: <SportFacilityAdditional activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} type={type} />
    },
    {
      formName: FormNames.MEDIA_FORM,
      stepName: "Media",
      type: StepType.ACTION,
      component: <Media activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} />
    },
    {
      formName: FormNames.PRICING_FORM,
      stepName: "Pricing",
      type: StepType.ACTION,
      component: <Pricing activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} />
    },
    {
      stepName: "Preview data",
      type: StepType.ACTION,
      component: <BusinessDataPreview activeStep={activeStep} isLastStep={isLastStep} handleNext={handleNext} handleBack={handleBack} populatedData={populatedData} />
    },
    {
      stepName: "Finish",
      type: StepType.INFO,
      component: <SuccessStep businessName={businessName} id={newBusinessId}/>
    }
  ]

  return steps.map((step, index) => { return { ...step, stepIndex: index } });
}

function getSteps(type) {
  return getStepsInfo(type).map((stepInfo) => stepInfo.stepName);
}

function NewBusiness() {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(getSteps());
  const [newBusinessId, setNewBusinessId] = useState();

  const isLastActionStep = activeStep === getStepsInfo()?.findLastIndex((element) => element.type == StepType.ACTION);
  const isLastStep = activeStep === steps.length - 1;
  const [businessData, setBusinessData] = useState({});
  const [businessName, setBusinessName] = useState("");

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  }
  const closeSuccessSB = () => setSuccessSB(false);

  const getStepContent = (activeStep, isLastStep, handleNext, handleBack) => {
    return getStepsInfo(businessData.type, activeStep, isLastStep, handleNext, handleBack, businessName, businessData, newBusinessId).find((stepInfo) => stepInfo.stepIndex == activeStep).component;
  }

  const getFormName = () => {
    return getStepsInfo().find((stepInfo) => stepInfo.stepIndex == activeStep).formName;
  }

  const getPreviousFormName = () => {
    return getStepsInfo().find((stepInfo) => stepInfo.stepIndex == (activeStep - 1)).formName;
  }

  const saveNewBusiness = async (businessDataElement) => {
    console.log("SAVE NEW BUSINESS")

    let businessDataPopulated = businessData;
    if (businessDataElement) {
      businessDataPopulated = { ...businessData, ...businessDataElement };
    }

    let mainImage; 
    let coverImage;
    let galleryImages = [];
    try {
      if (businessDataPopulated.mediaPayload.mainImage) {
        const imageBase64 = businessDataPopulated.mediaPayload.mainImage.split(',')[1];
        const body = new FormData();
        const buff = Buffer.from(imageBase64, "base64"); 
        body.append('file', new Blob([buff], { type: `image/jpeg` }));
        const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
        mainImage = response?.data?.data?.fileName;
      }

      if (businessDataPopulated.mediaPayload.coverImage) {
        const imageBase64 = businessDataPopulated.mediaPayload.coverImage.split(',')[1];
        const body = new FormData();
        const buff = Buffer.from(imageBase64, "base64"); 
        body.append('file', new Blob([buff], { type: `image/jpeg` }));
        const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
        coverImage = response?.data?.data?.fileName;
      }

      if (businessDataPopulated.mediaPayload.gallery && businessDataPopulated.mediaPayload.gallery.length > 0) {
        for (let i = 0; i < businessDataPopulated.mediaPayload.gallery.length; i++) {
          console.log("krug "+ i)
          const body = new FormData();
          body.append('file', businessDataPopulated.mediaPayload.gallery[i]);
          const response = await CreateMultipartPostRequest(BackRequests.UPLOAD_MEDIA, body);
          console.log(response)
          galleryImages.push(response?.data?.data?.fileName);
        }
      }

    } catch (error) {
      console.log(error)
      openErrorSB(GetErrorMessage(error));
      throw error;
    }

    try {
      const businessItemPayload = {
        name: businessDataPopulated.name,
        description: businessDataPopulated.description,
        type: businessDataPopulated.type,
        ownerId: JSON.parse(localStorage.getItem("userId")),
        ageCategories: businessDataPopulated.ageCategory.map((ageCategory) => { return ageCategory.value }),
        additional: businessDataPopulated.additional,
        mediaPayload: {
          mainImage: mainImage,
          coverImage: coverImage,
          gallery: galleryImages,
        },
        addresses: businessDataPopulated.addresses.map((item) => { return {...item, country: getCountryValue(item.country)}}),
        gender: businessDataPopulated.gender.value,
        skillLevels: businessDataPopulated.skillLevel.map((skillLevel) => { return skillLevel.value }),
        sports: businessDataPopulated.sport.map((sport) => { return sport.value }),
        pricelist: businessDataPopulated.pricelist,
        contactData: {
          ... (businessDataPopulated.website && { website: businessDataPopulated.website }),
          ... (businessDataPopulated.email && { email: businessDataPopulated.email }),
          ... (businessDataPopulated.contactPhone && { contactPhone: businessDataPopulated.contactPhone }),
        },
        socialNetworkAccounts: {
          ... (businessDataPopulated.instagramAccount && { instagramAccount: businessDataPopulated.instagramAccount }),
          ... (businessDataPopulated.twitterAccount && { twitterAccount: businessDataPopulated.twitterAccount }),
          ... (businessDataPopulated.facebookAccount && { facebookAccount: businessDataPopulated.facebookAccount }),
          ... (businessDataPopulated.tiktokAccount && { tiktok: businessDataPopulated.tiktokAccount }),
        }
      }

      switch (businessDataPopulated.type) {
        case BusinessItemTypes.EVENT:
          businessItemPayload.eventPayload = {
            dateStart: businessDataPopulated.eventDateStart,
            dateFinish: businessDataPopulated.eventDateFinish,
          }
          break;
        case BusinessItemTypes.PERSONAL_COACH:
          businessItemPayload.personalCoachPayload = {
            numberOfFreeTrainings: businessDataPopulated.numberOfFreeTrainings,
            yearOfBirth: businessDataPopulated.yearOfBirth,
            aboutMe: businessDataPopulated.aboutMe,
          }
          break;
        case BusinessItemTypes.SPORT_FACILITY:
          businessItemPayload.sportFacilityPayload = {
            numberOfFreeTrainings: businessDataPopulated.numberOfFreeTrainings,
            workingHours: businessDataPopulated.workingHours.map((workingHours) => {
              const { day, ...other } = workingHours;
              return {
                day: day.value,
                ...other
              }
            })
          }
          break;
        case BusinessItemTypes.GROUP_ACTIVITY:
          businessItemPayload.groupActivityPayload = {
            schedule: businessDataPopulated.schedule,
            aboutPrograms: businessDataPopulated.aboutPrograms,
            numberOfFreeTrainings: businessDataPopulated.numberOfFreeTrainings
          }
          break;
      }
      console.log(businessItemPayload)
      const addBusinessResp = await CreatePostRequest(BackRequests.ADD_BUSINESS_ITEM, businessItemPayload);
      setNewBusinessId(addBusinessResp?.data?.data?.id)
      setBusinessData({});
      clearInitialValues();
      openSuccessSB("Business request created");
    } catch (error) {
      console.log(error)
      openErrorSB(GetErrorMessage(error));
      throw error;
    }
  }

  const handleNext = async (businessDataElement) => {
    console.log("HANDLE NEXT")
    // TODO: Check moving back and forward through wizzard

    // NOTE: For preview data step businessDataElement is undefined
    if (businessDataElement) {
      // Set data for finished step
      setBusinessData({ ...businessData, ...businessDataElement });

      // Set business name
      if (businessName == "" && businessDataElement?.name) {
        setBusinessName(businessDataElement.name);
      }

      // Set selected type for validations and dynamic fields
      if (businessDataElement?.type) {
        setSelectedType(businessDataElement.type);
        clearInitialValues();
        setSteps(getSteps(businessDataElement.type));
      }

      const formName = getFormName()
      if(formName) {
        // Set collected data as inital values for this form
        setPopulatedValues(businessDataElement, getFormName());
      }
    }

    // If this is last action step, invoke saving new business
    if (isLastActionStep) {
      try {
        await saveNewBusiness(businessDataElement);
      } catch (error) {
        // TODO: Handle error
      }
    }

    // If this is not last step, move to next one
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    console.log("HANDLE BACK")
    // setPopulatedValues(businessDataElement, getPreviousFormName());
    setActiveStep(activeStep - 1);
  }

  return (
    <PageLayout>
      <SoftBox align="center">
        <HeaderMenu />
      </SoftBox>
      <SoftBox pt={3} pb={8} flex="1" display="flex" sx={({breakpoints, transitions, functions: {pxToRem}}) => ({
        p: 3,
        position: "relative",
      })}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2}>
                <SoftBox>
                  {getStepContent(activeStep, isLastStep, handleNext, handleBack)}
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
        {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
        {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default NewBusiness;
