/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Formik, Form } from "formik";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import FormField from "components/common/FormField";
import SoftSelect from "components/SoftSelect";
import SoftBox from "components/SoftBox";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Form schemas
import useValidations from "layouts/pages/home/schemas/validations";
import useFormFields from "layouts/pages/home/schemas/form";
import useInitialValues from "layouts/pages/home/schemas/initialValues";
import FormNames from "layouts/pages/home/schemas/form-names.enum";

import { useTranslation } from "react-i18next";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { Card, Divider, FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import breakpoints from "assets/theme/base/breakpoints";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getBusinessItemOptionByValue } from "helpers/enums/business-item.enum";

function Filters({ handleSearch, type, allSearch, initialBusinessName, initialLocation }) {
  const { t, i18n } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isCleared, setIsCleared] = useState(false);

  const [canCollapse, setCanCollapse] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [additionalOptionsFullArray, setAdditionalOptionsFullArray] = useState([]);

  const [skillsOptions, setSkillsOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);

  const [sportOptions, setSportOptions] = useState([]);
  const [sportOptionsFullArray, setSportOptionsFullArray] = useState([]);
  const { initial, getInitialValuesForSearch } = useInitialValues();

  const formValidation = useValidations()[FormNames.SEARCH];
  const formFields = useFormFields(t)[FormNames.SEARCH];
  const [initialValues, setInitialValues] = useState(initial[FormNames.SEARCH]);

  const handleSubmit = async (values) => {
    try {
      console.log("SEARCH HANDLE SUBMIT")
      console.log(values)
      setInitialValues(values);

      handleSearch({
        ...(values[formFields.businessName.name] && { name: values[formFields.businessName.name] }),
        ...(values[formFields.location.name] && { location: values[formFields.location.name] }),
        ...(values[formFields.category.name] && { category: values[formFields.category.name].value }),
        ...(values[formFields.searchGender.name] && { gender: values[formFields.searchGender.name].value }),
        ...(values[formFields.searchSport.name] && values[formFields.searchSport.name].length > 0 && { sports: values[formFields.searchSport.name] }),
        ...(values[formFields.reviewScore.name] && { reviewScore: Number(values[formFields.reviewScore.name]) }),
        ...(values[formFields.searchAdditional.name] && values[formFields.searchAdditional.name].length > 0 && { additional: values[formFields.searchAdditional.name] }),
        ...(values[formFields.searchAgeCategory.name] && values[formFields.searchAgeCategory.name].length > 0 && { ageCategories: values[formFields.searchAgeCategory.name] }),
        ...(values[formFields.searchSkillLevel.name] && values[formFields.searchSkillLevel.name].length > 0 && { skillLevels: values[formFields.searchSkillLevel.name] }),

      });
    } catch (error) {
      console.log(error)
      // TODO
      // openErrorSB(GetErrorMessage(error));
    }
  };

  const getAdditionalOptions = async () => {
    try {
      // TODO
      const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_ADDITIONAL + type);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getSkillsOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_SKILL_LEVELS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getAllAdditionalOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_ALL_ADDITIONAL);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getAgeOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_AGE_CATEGORIES);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getSportOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_SPORTS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const resetFormData = async (setFieldValue, values, resetForm) => {
    console.log("resetFormData")

    const clearData = getInitialValuesForSearch();
    console.log("clearData", clearData)
    setIsCleared(true);

    setFieldValue(formFields.searchSport.name, clearData[formFields.searchSport.name])
    console.log(values)
    values = clearData
    setRefreshKey((prevKey) => prevKey + 1);
    // resetForm();
    // values = clearData;
    // for (const key in clearData) {
    //   console.log(key)
    //   console.log(clearData[key])
    //   setFieldValue(key, clearData[key]);
    // }
    // setFieldValue("iva", "test");
  }

  useEffect(() => {
    const loadData = async () => {
      let additionalOptionsResp = [];
      if (!allSearch) {
        additionalOptionsResp = await getAdditionalOptions();
      } else {
        additionalOptionsResp = await getAllAdditionalOptions();
      }
      console.log(additionalOptionsResp)
      setAdditionalOptionsFullArray(additionalOptionsResp);
      setAdditionalOptions(additionalOptionsResp.slice(0, 5));
      const sportOptionsResp = await getSportOptions();
      setSportOptionsFullArray(sportOptionsResp);
      setSportOptions(sportOptionsResp.slice(0, 5));

      setSkillsOptions(await getSkillsOptions());
      setAgeOptions(await getAgeOptions());
    }
    loadData();
  }, []);

  useEffect(() => {
    function handleCanCollapse() {
      return window.innerWidth < breakpoints.values.sm
        ? setCanCollapse(true)
        : setCanCollapse(false);
    }

    /**
     The event listener that's calling the handleCanCollapse function when resizing the window.
    */
    window.addEventListener("resize", handleCanCollapse);

    // Call the handleCanCollapse function to set the state with the initial value.
    handleCanCollapse();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleCanCollapse);
  }, [canCollapse]);

  return (
    <Card>
      <SoftBox p={2}>
        <Formik
          enableReinitialize
          initialValues={{
            ...initialValues,
            ...(initialBusinessName && !isCleared && {businessName: initialBusinessName}),
            ...(initialLocation && !isCleared && {location: initialLocation}),
            ...(type && !isCleared && {category:  getBusinessItemOptionByValue(type, t)})
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}
          key={refreshKey}
        >
          {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
            <Form id={FormNames.SEARCH} autoComplete="off">
              <SoftBox>
                <SoftBox display="flex" justifyContent="space-between" onClick={() => {
                  if (canCollapse) {
                    console.log("click")
                    setShowFilter(!showFilter)
                  }
                }}>
                  <SoftBox display="flex" alignItems="center">
                    <FilterAltIcon size="medium" />
                    <SoftTypography variant="h5" fontWeight="bold" align="left">
                      {t('searchForm.labelFilter')}
                    </SoftTypography>
                  </SoftBox>
                  {canCollapse && (
                    <SoftBox display="flex" alignItems="center">
                      {!showFilter && <ExpandMoreIcon fontSize="medium" />}
                      {showFilter && <ExpandLessIcon fontSize="medium" />}
                    </SoftBox>)}
                </SoftBox>

                {(showFilter || !canCollapse) && (
                  <SoftBox mt={1}>
                    <hr />
                    <SoftBox mt={1}>
                      <Grid container align="left">
                        <Grid item xs={12} sm={12} sx={{ marginBottom: -1.5 }}>
                          <FormField
                            size="small"
                            type={formFields.businessName.type}
                            label={formFields.businessName.label}
                            name={formFields.businessName.name}
                            value={values.businessName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ marginBottom: -1.5 }}>
                          <FormField
                            size="small"
                            type={formFields.location.type}
                            label={formFields.location.label}
                            name={formFields.location.name}
                            value={values.location}
                          />
                        </Grid>
                          <Grid item xs={12} sm={12}>
                            <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {formFields.category.label}
                              </SoftTypography>
                              <Tooltip title={t('searchForm.categoryDescription')} arrow={false} PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -7],
                                    },
                                  },
                                ],
                              }}
                                enterTouchDelay={0}
                                leaveDelay={canCollapse ? 5000 : 0}
                              >
                                <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                              </Tooltip>
                            </SoftBox>
                            <SoftSelect
                              size="small"
                              // displayEmpty
                              value={values.category}
                              label={formFields.category.label}
                              name={formFields.category.name}
                              placeholder={formFields.category.placeholder}
                              options={formFields.category.options}
                              onChange={async (event) => {
                                console.log(event)
                                setFieldValue(formFields.category.name, event);
                                const selectedAdditional = values[formFields.searchAdditional.name];
                                console.log(selectedAdditional);

                                let additionalOptionsResp = [];
                                if (event != null) {
                                  type = event.value
                                  additionalOptionsResp = await getAdditionalOptions();
                                } else {
                                  additionalOptionsResp = await getAllAdditionalOptions();
                                }
                                setAdditionalOptionsFullArray(additionalOptionsResp);
                                setAdditionalOptions(additionalOptionsResp.slice(0, 5));

                                const keepSelected = [];
                                for (const selectedItem of selectedAdditional) {
                                  if(additionalOptionsResp.find((item)=>item.value == selectedItem) != undefined) {
                                    keepSelected.push(selectedItem);
                                  }
                                }
                                setFieldValue(formFields.searchAdditional.name, keepSelected);
                              }}
                              isClearable
                            />
                          </Grid>

                        <Grid item xs={12} sm={12}>
                          <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {formFields.searchGender.label}
                            </SoftTypography>
                            <Tooltip title={t('searchForm.genderPolicyDescription')} arrow={false} PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            enterTouchDelay={0}
                            leaveDelay={canCollapse ? 5000 : 0}
                            >
                              <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                            </Tooltip>
                          </SoftBox>
                          <SoftSelect
                            size="small"
                            displayEmpty
                            label={formFields.searchGender.label}
                            name={formFields.searchGender.name}
                            placeholder={formFields.searchGender.placeholder}
                            options={formFields.searchGender.options}
                            onChange={(event) => {
                              setFieldValue(formFields.searchGender.name, event);
                            }}
                          />
                          <Divider sx={{ marginBottom: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={11}>
                          <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {formFields.searchSkillLevel.label}
                            </SoftTypography>
                            <Tooltip title={t('searchForm.skillLevelDescription')} arrow={false} PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            enterTouchDelay={0}
                            leaveDelay={canCollapse ? 5000 : 0}
                            >
                              <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                            </Tooltip>
                          </SoftBox>
                          <SoftBox mb={1.5}>
                            {skillsOptions.map((option) => (
                              <SoftBox key={option.value + "-soft-box"}>
                                <FormControlLabel sx={{ marginLeft: 0, marginRight: 0 }}
                                  control={
                                    <Checkbox
                                      name={formFields.searchSkillLevel.name}
                                      id={option.value + "-option"}
                                      //checked={values[formFields.searchSkillLevel.name].findIndex((value) => value == option.value) > -1}   /// kada sklonim komentar puca
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          values[formFields.searchSkillLevel.name].push(option.value)
                                        } else {
                                          const index = values[formFields.searchSkillLevel.name].findIndex((value) => value == option.value);
                                          if (index > -1) {
                                            values[formFields.searchSkillLevel.name].splice(index, 1);
                                          }
                                        }
                                        setFieldValue(formFields.searchSkillLevel.name, values[formFields.searchSkillLevel.name])
                                      }}
                                      sx={{ marginRight: 0.3 }}
                                    />
                                  }
                                  label={
                                    <SoftTypography
                                      variant="button"
                                      fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none" }}
                                      htmlFor={option.value + "-option"}
                                    >
                                      {option.label}
                                    </SoftTypography>
                                  }
                                />
                              </SoftBox>
                            ))}
                          </SoftBox>
                          <Divider sx={{ marginBottom: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {formFields.searchAgeCategory.label}
                            </SoftTypography>
                            <Tooltip title={t('searchForm.ageCategoryDescription')} arrow={false} PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            enterTouchDelay={0}
                            leaveDelay={canCollapse ? 5000 : 0}
                            >
                              <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                            </Tooltip>
                          </SoftBox>
                          <SoftBox mb={1.5}>
                            {ageOptions.map((option) => (
                              <SoftBox key={option.value + "-soft-box"}>
                                <FormControlLabel sx={{ marginLeft: 0, marginRight: 0 }}
                                  control={
                                    <Checkbox
                                      name={formFields.searchAgeCategory.name}
                                      //checked={values[formFields.searchAgeCategory.name].findIndex((value) => value == option.value) > -1}  /// kada sklonim komentar puca
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          values[formFields.searchAgeCategory.name].push(option.value)
                                        } else {
                                          const index = values[formFields.searchAgeCategory.name].findIndex((value) => value == option.value);
                                          if (index > -1) {
                                            values[formFields.searchAgeCategory.name].splice(index, 1);
                                          }
                                        }
                                        setFieldValue(formFields.searchAgeCategory.name, values[formFields.searchAgeCategory.name])
                                      }}
                                      sx={{ marginRight: 0.3 }}
                                    />
                                  }
                                  label={
                                    <SoftTypography
                                      variant="button"
                                      fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none", marginRight: 0 }}
                                    >
                                      {option.label}
                                    </SoftTypography>
                                  }
                                />
                              </SoftBox>
                            ))}
                          </SoftBox>
                          <Divider sx={{ marginBottom: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {formFields.searchSport.label}
                            </SoftTypography>
                            <Tooltip title={t('searchForm.sportDescription')} arrow={false} PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            enterTouchDelay={0}
                            leaveDelay={canCollapse ? 5000 : 0}
                            >
                              <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                            </Tooltip>
                          </SoftBox>
                          <SoftBox mb={1.5}>
                            {sportOptions.map((option) => (
                              <SoftBox key={option.value + "-soft-box"}>
                                <FormControlLabel sx={{ marginLeft: 0, marginRight: 0 }}
                                  control={
                                    <Checkbox
                                      name={formFields.searchSport.name}
                                      //checked={values[formFields.searchSport.name].findIndex((value) => value == option.value) > -1}  /// kada sklonim komentar puca
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          values[formFields.searchSport.name].push(option.value)
                                        } else {
                                          const index = values[formFields.searchSport.name].findIndex((value) => value == option.value);
                                          if (index > -1) {
                                            values[formFields.searchSport.name].splice(index, 1);
                                          }
                                        }
                                        setFieldValue(formFields.searchSport.name, values[formFields.searchSport.name])
                                      }}
                                      sx={{ marginRight: 0.3 }}
                                    />
                                  }
                                  label={
                                    <SoftTypography
                                      variant="button"
                                      fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none" }}
                                    >
                                      {option.label}
                                    </SoftTypography>
                                  }
                                />
                              </SoftBox>
                            ))}
                          </SoftBox>
                          {sportOptions?.length != sportOptionsFullArray?.length && (
                            <SoftBox ml={0.5} display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {
                              setSportOptions(sportOptionsFullArray);
                            }}>
                              <SoftTypography variant="caption" align="left" color="primary">
                                {t('searchForm.showAll')}
                              </SoftTypography>
                              <ExpandMoreIcon size="small" color="primary" />
                            </SoftBox>
                          )}
                          {sportOptions?.length > 5 && (
                            <SoftBox ml={0.5} display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {
                              setSportOptions(sportOptionsFullArray.slice(0, 5));
                            }}>
                              <SoftTypography variant="caption" align="left" color="primary">
                                {t('searchForm.showLess')}
                              </SoftTypography>
                              <ExpandLessIcon size="small" color="primary" />
                            </SoftBox>
                          )}
                          <Divider sx={{ marginBottom: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {formFields.searchAdditional.label}
                            </SoftTypography>
                            <Tooltip title={t('searchForm.additionalDescription')} arrow={false} PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            enterTouchDelay={0}
                            leaveDelay={canCollapse ? 5000 : 0}
                            >
                              <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                            </Tooltip>
                          </SoftBox>
                          <SoftBox mb={1.5}>
                            {additionalOptions.map((option) => (
                              <SoftBox key={option.value + "-soft-box"}>
                                <FormControlLabel sx={{ marginLeft: 0, marginRight: 0 }}
                                  control={
                                    <Checkbox
                                      name={formFields.searchAdditional.name}
                                      // checked={values[formFields.searchAdditional.name].findIndex((value) => value == option.value) > -1} /// kada sklonim komentar puca
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          values[formFields.searchAdditional.name].push(option.value)
                                        } else {
                                          const index = values[formFields.searchAdditional.name].findIndex((value) => value == option.value);
                                          if (index > -1) {
                                            values[formFields.searchAdditional.name].splice(index, 1);
                                          }
                                        }
                                        setFieldValue(formFields.searchAdditional.name, values[formFields.searchAdditional.name])
                                      }}
                                      sx={{ marginRight: 0.3 }}
                                    />
                                  }
                                  label={
                                    <SoftTypography
                                      variant="button"
                                      fontWeight="regular"
                                      sx={{ cursor: "pointer", userSelect: "none" }}
                                    >
                                      {option.label}
                                    </SoftTypography>
                                  }
                                />
                              </SoftBox>
                            ))}
                          </SoftBox>
                          {additionalOptions?.length != additionalOptionsFullArray?.length && (
                            <SoftBox ml={0.5} display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {
                              setAdditionalOptions(additionalOptionsFullArray);
                            }}>
                              <SoftTypography variant="caption" align="left" color="primary">
                                {t('searchForm.showAll')}
                              </SoftTypography>
                              <ExpandMoreIcon size="small" color="primary" />
                            </SoftBox>
                          )}
                          {additionalOptions?.length > 5 && (
                            <SoftBox ml={0.5} display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => {
                              setAdditionalOptions(additionalOptionsFullArray.slice(0, 5));
                            }}>
                              <SoftTypography variant="caption" align="left" color="primary">
                                {t('searchForm.showLess')}
                              </SoftTypography>
                              <ExpandLessIcon size="small" color="primary" />
                            </SoftBox>
                          )}
                          <Divider sx={{ marginBottom: 1 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                          <SoftBox ml={0.5} mb={1} mt={1} lineHeight={0} display="flex" alignItems="center" width="100%">
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {formFields.reviewScore.label}
                            </SoftTypography>
                            <Tooltip title={t('searchForm.reviewScoreDescription')} arrow={false} PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -7],
                                  },
                                },
                              ],
                            }}
                            enterTouchDelay={0}
                            leaveDelay={canCollapse ? 5000 : 0}
                            >
                              <HelpOutlineIcon fontSize="small" color="secondary" sx={{ marginLeft: "4px" }} />
                            </Tooltip>
                          </SoftBox>
                          <RadioGroup name={formFields.reviewScore.name}
                            onChange={(event) => {
                              setFieldValue(formFields.reviewScore.name, event.target.value)
                            }}
                          >
                            {formFields.reviewScore.options.map((option) => (
                              <FormControlLabel
                                key={option.value + "-radio-button"}
                                value={option.value}
                                control={<Radio sx={{ fontWeight: "normal", marginRight: 0.3 }} />}
                                label={<SoftTypography
                                  variant="button"
                                  fontWeight="regular"
                                  sx={{ cursor: "pointer", userSelect: "none" }}
                                >
                                  {option.label}
                                </SoftTypography>} sx={{ marginLeft: "0", }} />
                            ))}
                          </RadioGroup>
                          <Divider sx={{ marginBottom: 1 }} />
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mt={1.5} display="flex" justifyContent="center">
                      <SoftButton
                        color="primary"
                        type="submit"
                      >{t('searchForm.btnSearch')}</SoftButton>
                    </SoftBox>
                    <SoftBox mt={1} display="flex" justifyContent="center">
                      <SoftButton color="dark" variant="text" onClick={() => resetFormData(setFieldValue, values, resetForm)}>
                        <FilterAltOffIcon mr={1} />&nbsp;
                        {t('searchForm.clearFilters')}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                )}
              </SoftBox>
            </Form>
          )}
        </Formik>
      </SoftBox>
    </Card>
  );
}

export default Filters;
