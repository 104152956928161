/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

export default styled("div")(({ theme }) => {
  const { palette, borders, typography } = theme;

  const { borderRadius } = borders;
  const { size } = typography;
  const { text } = palette;
  const { dark, grey } = colors;

  return {
    "& .ql-toolbar": {
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
    },

    "& .ql-container": {
      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`,
    },

    "& .ql-editor": {
      "& p": {
        fontSize: size.md,
        color: dark.main,
        fontSize: pxToRem(16),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1.6,
      },

      "& ul li": {
        color: dark.main,
        fontSize: pxToRem(16),
        lineHeight: 1.6,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },

      height: "200px",
      overflowY: scroll,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: pxToRem(16),
      color: dark.main,

      "&.ql-blank::before": {
        color: grey[700],
        opacity: 0.5,
        fontStyle: "normal",
      },
    },

    ".ql-size-10px": { fontSize: "10px" },
    ".ql-size-12px": { fontSize: "12px" },
    ".ql-size-16px": { fontSize: "16px" },
    ".ql-size-18px": { fontSize: "18px" },
    ".ql-size-24px": { fontSize: "24px" },
    ".ql-size-32px": { fontSize: "32px" },
    ".ql-size-48px": { fontSize: "48px" },

    /* Override default "Normal" text in dropdown with actual sizes */
    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before": {
      content: '"10px"'
    },

    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before": {
      content: '"12px"'
    },

    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before": {
      content: '"16px"'
    },

    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before": {
      content: '"18px"'
    },

    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before": {
      content: '"24px"'
    },

    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before": {
      content: '"32px"'
    },

    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before": {
      content: '"48px"'
    },

    /* Rename "normal" (false) option to "Default size" */
    ".ql-snow .ql-picker.ql-size .ql-picker-item[data-value='false']::before": {
      content: '"Default size"'
    },

    /* Change the label of the currently selected "normal" option to "Default size" */
    ".ql-snow .ql-picker.ql-size .ql-picker-label[data-value='false']::before": {
      content: '"Default size"'
    }
  };
});
