/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import useFormFields from "layouts/authentication/reset-password-confirm/schemas/form";

const useValidations = (t) => {
  const { newPassword, repeatPassword } = useFormFields(t);

  const validations = Yup.object().shape({
    [newPassword.name]: Yup.string()
      .required(newPassword.errorMsg)
      .min(5, newPassword.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("newPassword"), null], repeatPassword.invalidMsg),
  });

  return validations;
};

export default useValidations;
