/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// react-router components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// DefaultNavbar dropdown menus
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import DocsMenu from "examples/Navbars/DefaultNavbar/Menus/DocsMenu";

import moveonLogo from "assets/images/moveon/moveon-logo-orange.svg";
import PublicDefaultNuvbarElements from "../PublicDefaultNuvbarElements";
import TranslateSelect from "components/common/TranslateSelect";

function DefaultNavbar({routes, transparent, light, action, action2}) {
  const [pagesMenu, setPagesMenu] = useState(false);
  const [authenticationMenu, setAuthenticationMenu] = useState(false);
  const [ecommerceMenu, setEcommerceMenu] = useState(false);
  const [applicationsMenu, setApplicationsMenu] = useState(false);
  const [docsMenu, setDocsMenu] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openPagesMenu = ({currentTarget}) => setPagesMenu(currentTarget);
  const closePagesMenu = () => setPagesMenu(false);
  const openAuthenticationMenu = ({currentTarget}) => setAuthenticationMenu(currentTarget);
  const closeAuthenticationMenu = () => setAuthenticationMenu(false);
  const openEcommerceMenu = ({currentTarget}) => setEcommerceMenu(currentTarget);
  const closeEcommerceMenu = () => setEcommerceMenu(false);
  const openApplicationsMenu = ({currentTarget}) => setApplicationsMenu(currentTarget);
  const closeApplicationsMenu = () => setApplicationsMenu(false);
  const openDocsMenu = ({currentTarget}) => setDocsMenu(currentTarget);
  const closeDocsMenu = () => setDocsMenu(false);
  const openMobileNavbar = ({currentTarget}) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <SoftBox
        py={1}
        px={{xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5}}
        my={2}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="section"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({palette: {transparent: transparentColor, white}, functions: {rgba}}) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
        //style={{border: 1, borderStyle: "solid", borderColor: "black"}}
      >
        <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1}>
          <SoftTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
            <img src={moveonLogo}
                 alt="Move On"
                 style={{ height: "4vh" }}
            />
          </SoftTypography>
        </SoftBox>
        <PublicDefaultNuvbarElements light={light}/>
        {action &&
            (action.type === "internal" ? (
                <SoftBox display={{ xs: "none", lg: "inline-block" }}>
                  <SoftButton
                      component={Link}
                      to={action.route}
                      variant="gradient"
                      color={action.color ? action.color : "success"}
                      size="small"
                      circular
                  >
                    {action.label}
                  </SoftButton>
                </SoftBox>
            ) : (
                <SoftBox display={{ xs: "none", lg: "inline-block" }}>
                  <SoftButton
                      component="a"
                      href={action.route}
                      target="_blank"
                      rel="noreferrer"
                      variant="gradient"
                      color={action.color ? action.color : "info"}
                      size="small"
                      circular
                  >
                    {action.label}
                  </SoftButton>
                </SoftBox>
            ))}
        {action2 &&
            (action2.type === "internal" ? (
                <SoftBox display={{ xs: "none", lg: "inline-block" }} >
                  <SoftButton
                      component={Link}
                      to={action2.route}
                      variant="gradient"
                      color={action2.color ? action2.color : "info"}
                      size="small"
                      circular
                  >
                    {action2.label}
                  </SoftButton>
                </SoftBox>
            ) : (
                <SoftBox display={{ xs: "none", lg: "inline-block" }}>
                  <SoftButton
                      component="a"
                      href={action2.route}
                      target="_blank"
                      rel="noreferrer"
                      variant="gradient"
                      color={action2.color ? action2.color : "info"}
                      size="small"
                      circular
                  >
                    {action2.label}
                  </SoftButton>
                </SoftBox>
            ))}
        <SoftBox
          display={{xs: "inline-block", lg: "none"}}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{cursor: "pointer"}}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </SoftBox>
        <TranslateSelect />
      </SoftBox>
      <PagesMenu routes={routes} open={pagesMenu} close={closePagesMenu}/>
      <AuthenticationMenu
        routes={routes}
        open={authenticationMenu}
        close={closeAuthenticationMenu}
      />
      <EcommerceMenu routes={routes} open={ecommerceMenu} close={closeEcommerceMenu}/>
      <ApplicationsMenu routes={routes} open={applicationsMenu} close={closeApplicationsMenu}/>
      <DocsMenu routes={routes} open={docsMenu} close={closeDocsMenu}/>
      {mobileView && (
        <DefaultNavbarMobile routes={routes} open={mobileNavbar} close={closeMobileNavbar}/>
      )}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
