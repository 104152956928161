/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import WorkingHours from '@mui/icons-material/AlarmOn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'


import { Card, List, ListItem, ListItemText, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import PagesHeaderCell from 'layouts/pages/home/components/Pages/PagesHeaderCell';
import PagesBodyCell from 'layouts/pages/home/components/Pages/PagesBodyCell';
import { useEffect } from 'react';
import SoftBadge from 'components/SoftBadge';
import { getDisplayBusinessItemType } from 'helpers/enums/business-item.enum';
import { BusinessItemTypes } from 'helpers/enums/business-item.enum';
import BusinessGalleryImages from '../BusinessGalleryImages';
import { Formik, Form } from "formik";
import FormNames from '../../schemas/form-names.enum';
import { getCountryLabel } from "layouts/pages/business/business-form-helpers";
import { useTranslation } from "react-i18next";

import LocationOnIcon from '@mui/icons-material/LocationOn';

function BusinessDataPreview({ activeStep, isLastStep, handleNext, handleBack, populatedData }) {
    const { t, i18n } = useTranslation();

    const handleSubmit = async (values, actions) => {
        try {
            console.log("BusinessDataPreview HANDLE SUBMIT")
            console.log(values)

            handleNext()
        } catch (error) {
            // TODO
            // openErrorSB(GetErrorMessage(error));
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                heatmaps: '',
            }}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, isSubmitting }) => (
                <Form id={FormNames.PREVIEW_DATA_FORM} autoComplete="off">
                    <SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                            Preview business data
                        </SoftTypography>
                        <SoftTypography variant="body2" color="text" mt={2}>
                            Please check the entered data and if everything is correct, continue with the process to create a new business.
                        </SoftTypography>
                        <SoftBox my={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={7}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} display="grid">
                                            <SoftBox display="inline-flex">
                                                <SoftTypography variant="h3" fontWeight="medium" textTransform="capitalize">
                                                    {populatedData.name}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex">
                                                <SoftTypography variant="body2" color="text" fontWeight="medium" textTransform="capitalize" mb={2}>
                                                    {getDisplayBusinessItemType(populatedData.type, t)}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox>
                                                <div className="post__content" dangerouslySetInnerHTML={{ __html: populatedData?.description }}></div>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                {
                                    populatedData?.type == BusinessItemTypes.PERSONAL_COACH && (
                                        <Grid item xs={12} lg={7}>
                                            <Card sx={{ height: "100%" }}>
                                                <SoftBox p={3} display="grid">
                                                    <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                        About me
                                                    </SoftTypography>
                                                    <SoftBox display="inline-flex">
                                                        <SoftTypography variant="body2" color="text">
                                                            Year of birth:&nbsp;
                                                        </SoftTypography>
                                                        <SoftTypography variant="body2" fontWeight="medium" textTransform="capitalize">
                                                            {populatedData.yearOfBirth ? populatedData.yearOfBirth : "/"}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftBox mt={1} pl={1}>
                                                        {populatedData.aboutMe ? (
                                                            <div className="post__content" dangerouslySetInnerHTML={{ __html: populatedData?.aboutMe }}></div>
                                                        ) : "/"}
                                                    </SoftBox>
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    )
                                }
                                {
                                    populatedData?.type == BusinessItemTypes.GROUP_ACTIVITY && (
                                        <Grid item xs={12} lg={7}>
                                            <Card sx={{ height: "100%" }}>
                                                <SoftBox p={3} display="grid">
                                                    <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                        About programs
                                                    </SoftTypography>
                                                    <SoftBox>
                                                        {populatedData?.aboutPrograms ? (
                                                            <div className="post__content" dangerouslySetInnerHTML={{ __html: populatedData?.aboutPrograms }}></div>
                                                        ) : "/"}
                                                    </SoftBox>
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    )
                                }
                                {
                                    populatedData?.type == BusinessItemTypes.SPORT_FACILITY &&
                                    (<Grid item xs={12} lg={7}>
                                        <Card sx={{ height: "100%" }}>
                                            <SoftBox p={3} display="grid">
                                                <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                    Working hours
                                                </SoftTypography>
                                                {populatedData?.workingHours?.length > 0 ?
                                                    (populatedData?.workingHours.map((item, index) => (
                                                        <SoftBox display="inline-flex" lineHeight={1.375} key={"workingHour-" + index}>
                                                            <WorkingHours />
                                                            <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                                {item.day.label}: {item.startTime} - {item.endTime}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    ))) : "/"}
                                            </SoftBox>
                                        </Card>
                                    </Grid>)
                                }
                                <Grid item xs={12} lg={5}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} display="grid">
                                            <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                Addresses
                                            </SoftTypography>
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                {populatedData.addresses.map((item, index) =>
                                                    <ListItem key={"address-" + index} sx={{ marginBottom: '10px' }}>
                                                        <SoftBox mr={1}>
                                                            <LocationOnIcon />
                                                        </SoftBox>
                                                        <ListItemText
                                                            primary={
                                                                <SoftTypography variant="body2" textTransform="capitalize">{item.addressLine + " " + item.addressNumber}</SoftTypography>
                                                            }
                                                            secondary={
                                                                <SoftTypography variant="body2" textTransform="capitalize" color="text" style={{ fontSize: '15px' }}>{item.city + ", " + item.zipCode + ", " + item.country} </SoftTypography>
                                                            }
                                                        />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} display="grid">
                                            <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                Contact data
                                            </SoftTypography>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <LanguageIcon fontSize="small" />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.website ? populatedData.website : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <EmailIcon fontSize="small" />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.email ? populatedData.email : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <PhoneIcon fontSize="small" />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.contactPhone ? populatedData.contactPhone : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <InstagramIcon fontSize="small" />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.socialNetworkAccounts?.instagramAccount ? populatedData.socialNetworkAccounts?.instagramAccount : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <FacebookIcon fontSize="small" />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.socialNetworkAccounts?.facebookAccount ? populatedData.socialNetworkAccounts?.facebookAccount : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <TwitterIcon fontSize="small" />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.socialNetworkAccounts?.twitterAccount ? populatedData.socialNetworkAccounts?.twitterAccount : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <FontAwesomeIcon icon={faTiktok} />
                                                <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                                                    {populatedData.socialNetworkAccounts?.tiktokAccount ? populatedData.socialNetworkAccounts?.tiktokAccount : "/"}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} display="grid">
                                            <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                Program details
                                            </SoftTypography>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <SoftTypography variant="body2" color="text">
                                                    Age category:&nbsp;
                                                </SoftTypography>
                                                <SoftBox>
                                                    {populatedData?.ageCategory?.length > 0 ?
                                                        (populatedData?.ageCategory?.map((item, index) => (
                                                            <SoftBadge style={{ margin: '0 0 0 5px' }} key={"ageCategory-" + index} variant="contained" color="info" badgeContent={item.label} />
                                                        ))) : "/"}
                                                </SoftBox>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <SoftTypography variant="body2" color="text">
                                                    Gender:&nbsp;
                                                </SoftTypography>
                                                <SoftBox>
                                                    {populatedData?.gender ?
                                                        <SoftBadge style={{ margin: '0 0 0 5px' }} variant="contained" color="success" badgeContent={populatedData?.gender?.label} />
                                                        : "/"}
                                                </SoftBox>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <SoftTypography variant="body2" color="text">
                                                    Skill level:&nbsp;
                                                </SoftTypography>
                                                <SoftBox>
                                                    {populatedData?.skillLevel?.length > 0 ?
                                                        (populatedData?.skillLevel?.map((item, index) => (
                                                            <SoftBadge style={{ margin: '0 0 0 5px' }} key={"skillLevel-" + index} variant="contained" color="error" badgeContent={item.label} />
                                                        ))) : "/"}
                                                </SoftBox>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <SoftTypography variant="body2" color="text">
                                                    Sport:&nbsp;
                                                </SoftTypography>
                                                <SoftBox>
                                                    {populatedData?.sport?.length > 0 ?
                                                        (populatedData?.sport?.map((item, index) => (
                                                            <SoftBadge style={{ margin: '0 0 0 5px' }} key={"sport-" + index} variant="contained" color="secondary" badgeContent={item.label} />
                                                        ))) : "/"}
                                                </SoftBox>
                                            </SoftBox>
                                            <SoftBox display="inline-flex" lineHeight={1.375}>
                                                <SoftTypography variant="body2" color="text">
                                                    Number of free trainings:&nbsp;
                                                </SoftTypography>
                                                <SoftTypography variant="body2" fontWeight="medium" textTransform="capitalize">
                                                    {populatedData.numberOfFreeTrainings}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} display="grid">
                                            <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                Additional
                                            </SoftTypography>
                                            <SoftBox>
                                                {populatedData?.additional?.length > 0 ?
                                                    (populatedData?.additional?.map((item, index) => (
                                                        <SoftBadge style={{ margin: '0 0 0 5px' }} key={"additional-" + index} variant="contained" color="success" badgeContent={item} container />
                                                    ))) : "/"}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                {
                                    populatedData?.type == BusinessItemTypes.GROUP_ACTIVITY && (
                                        <Grid item xs={12} lg={12}>
                                            <Card sx={{ height: "100%" }}>
                                                <SoftBox p={3}>
                                                    <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                        Schedule
                                                    </SoftTypography>
                                                    <SoftBox py={1} px={2}>
                                                        <TableContainer sx={{ boxShadow: "none" }}>
                                                            <Table>
                                                                <SoftBox component="thead">
                                                                    <TableRow>
                                                                        <PagesHeaderCell>Description</PagesHeaderCell>
                                                                        <PagesHeaderCell>Start time</PagesHeaderCell>
                                                                        <PagesHeaderCell>End time</PagesHeaderCell>
                                                                    </TableRow>
                                                                </SoftBox>
                                                                <TableBody>
                                                                    {populatedData && populatedData?.schedule?.map((item, index) => (
                                                                        <PagesBodyCell key={index} rows={[item.description, item.startTime, item.endTime]} />
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3}>
                                            <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                Pricing
                                            </SoftTypography>
                                            <SoftBox py={1} px={2}>
                                                <TableContainer sx={{ boxShadow: "none" }}>
                                                    <Table>
                                                        <SoftBox component="thead">
                                                            <TableRow>
                                                                <PagesHeaderCell>Description</PagesHeaderCell>
                                                                <PagesHeaderCell>Price</PagesHeaderCell>
                                                                <PagesHeaderCell>Discount</PagesHeaderCell>
                                                                <PagesHeaderCell>Discounted price</PagesHeaderCell>
                                                            </TableRow>
                                                        </SoftBox>
                                                        <TableBody>
                                                            {populatedData && populatedData?.pricelist?.map((item, index) => (
                                                                <PagesBodyCell key={index} rows={[index + 1 + ". " + item.description, item.price + " " + item.currency, item.discount + "%", item.discountedPrice + " " + item.currency]} />
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3}>
                                            <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                Main image
                                            </SoftTypography>
                                            <SoftBox component="img" src={populatedData.mediaPayload?.mainImage} width="100%" />
                                        </SoftBox>
                                    </Card>
                                </Grid>
                                {populatedData.mediaPayload?.coverImage &&
                                    <Grid item xs={12} lg={4}>
                                        <Card sx={{ height: "100%" }}>
                                            <SoftBox p={3}>
                                                <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                    Cover image
                                                </SoftTypography>
                                                <SoftBox component="img" src={populatedData.mediaPayload?.coverImage} width="100%" />
                                            </SoftBox>
                                        </Card>
                                    </Grid>
                                }
                                {(populatedData.mediaPayload?.gallery && populatedData.mediaPayload?.gallery.length > 0) &&
                                    <Grid item xs={12} lg={4}>
                                        <Card sx={{ height: "100%" }}>
                                            <SoftBox p={3} display="grid" sx={{ mx: "auto" }}>
                                                <SoftTypography variant="h6" fontWeight="bold" mb={1}>
                                                    Gallery
                                                </SoftTypography>
                                                <BusinessGalleryImages galleryImagesIds={populatedData.mediaPayload?.gallery} />
                                            </SoftBox>
                                        </Card>
                                    </Grid>
                                }
                            </Grid>
                        </SoftBox>
                        <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} />
                    </SoftBox>
                </Form>
            )}
        </Formik>
    );
}

export default BusinessDataPreview;
