/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the WavedBlogCard
import WavedBlogCardWavesRoot from "examples/Cards/BlogCards/WavedBlogCard/WavedBlogCardWavesRoot";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import BusinessInfoPreviewInCard from "../BusinessInfoPreviewInCard";

function WavedBlogCardTrainer({ image, title, addresses, sports, rating, maxDiscount, eventID, ratingCount}) {
  const { t, i18n } = useTranslation();
  const cardActionStyles = {
    display: "inline-block",
    textDecoration: "none",

    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
    },

    "& .material-icons, .material-icons-round,": {
      fontSize: "rem",
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
    {
      transform: `translateX(6px)`,
    },
  };

  return (
    <Card>
      <SoftBox p={1}>
        <Grid container spacing={3}>
          <Grid item xs={4} md={6} lg={6}>
            <SoftBox
              component="img"
              src={image}
              alt="message-image"
              borderRadius="lg"
              shadow="md"
              width="100%"
              height="25vh"
              style={{ objectFit: "cover" }}
            //display="inherit"
            />
            <SoftBox position="relative" height="3.125rem" overflow="hidden" zIndex={2} mt={-6.25}>
              <SoftBox position="absolute" width="100%" top={0} zIndex={1}>
                <SoftBox
                  component="svg"
                  position="relative"
                  width="100%"
                  height="3.125rem"
                  minHeight="3.125rem"
                  maxHeight="3.125rem"
                  mb={-0.875}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 24 150 40"
                  preserveAspectRatio="none"
                  shapeRendering="auto"
                >
                  <defs>
                    <path
                      id="card-wave"
                      d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                  </defs>
                  <WavedBlogCardWavesRoot>
                    <use xlinkHref="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30" />
                    <use xlinkHref="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)" />
                    <use xlinkHref="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)" />
                    <use xlinkHref="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)" />
                    <use xlinkHref="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)" />
                    <use xlinkHref="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)" />
                  </WavedBlogCardWavesRoot>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={8} md={6} lg={6} style={{ paddingLeft: '0px', height:"100%" }}>
            <SoftBox p={1} height="25vh">
                <BusinessInfoPreviewInCard
                  title={title}
                  addresses={addresses}
                  sports={sports}
                  rating={rating}
                  ratingCount={ratingCount}
                  maxDiscount={maxDiscount}
                  eventId={eventID}
                  showDivider
                />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
  );
}

// Typechecking props for the WavedBlogCard
WavedBlogCardTrainer.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addresses: PropTypes.string,
  sports: PropTypes.array.isRequired, // Array of sports
  rating: PropTypes.string.isRequired,
  ratingCount: PropTypes.number.isRequired,
  maxDiscount: PropTypes.number.isRequired,
  eventID:  PropTypes.number.isRequired,
};

export default WavedBlogCardTrainer;
