import * as Yup from "yup";
import useFormFields from "layouts/pages/home/schemas/form";
import FormNames from "layouts/pages/home/schemas/form-names.enum";
import {useTranslation} from "react-i18next";

const useValidations = () => {
    const {t} = useTranslation(); // Call useTranslation inside a hook or component

    const {
        [FormNames.SEARCH]: {
            businessName,
            location,
            category,
            searchGender,
            searchAdditional,
            searchSkillLevel,
            searchAgeCategory,
            searchSport,
            reviewScore,
        },
        [FormNames.SIMPLE_SEARCH]: {
            businessNameSimpleSearch,
            locationSimpleSearch,
            categorySimpleSearch,
        },
        [FormNames.MAKE_RESERVATION]: {
            firstName,
            lastName,
            email,
            service,
        },
    } = useFormFields(t);

    return {
        [FormNames.MAKE_RESERVATION]: Yup.object().shape({
            [firstName.name]: Yup.string().required(firstName.errorMsg),
            [lastName.name]: Yup.string().required(lastName.errorMsg),
            [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
        }),
    };
};

export default useValidations;
