/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import FormField from "components/common/FormField";

// Form
import useFormFields from "layouts/authentication/reset-password-confirm/schemas/form";
import useValidations from "layouts/authentication/reset-password-confirm/schemas/validations";
import useInitialValues from "layouts/authentication/reset-password-confirm/schemas/initialValues";
import { GetErrorMessage } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import runnerImg from "assets/images/auth-images/runner.jpg";
import BasicLayout from "../components/BasicLayout";
import { useTranslation } from "react-i18next";
import { sleep } from "helpers/general/helpers";
import RoutesNames from "helpers/enums/routes-map.enum";

function ResetPasswordConfirm() {
  const { t, i18n } = useTranslation();
  let params = useParams();
  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(false);

  const formFields = useFormFields(t);
  const validations = useValidations(t);
  const initialValues = useInitialValues(t);

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  const handleSubmit = async (values, actions) => {
    try {
      setIsSubmited(true);
      await CreatePostRequest(BackRequests.PASSWORD_RESET_CONFIRM + params.userId + "/" + params.token, {
        newPassword: values.newPassword,
      });
      openSuccessSB(t('auth.resetPasswordConfirm.successMsg'));
      await sleep(3)
      navigate(RoutesNames.SIGN_IN, { state: { redirectToHome: true } })
    } catch (error) {
      console.log("usao u error")
      openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <BasicLayout image={runnerImg}>
      <Card>
        <SoftBox p={3} pb={0} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {t('auth.resetPasswordConfirm.title')}
          </SoftTypography>
          <SoftTypography mt={3} variant="body2" color="text">
            {t('auth.resetPasswordConfirm.description')}
          </SoftTypography>
        </SoftBox>
        <SoftBox p={3}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id="reset-password-form-confirm" autoComplete="off">
                <SoftBox>
                  <SoftBox>
                    <FormField
                      type={formFields.newPassword.type}
                      label={formFields.newPassword.label}
                      name={formFields.newPassword.name}
                      value={values.newPassword}
                      error={errors.newPassword && touched.newPassword}
                      inputProps={{
                        type: "password",
                        autoComplete: "",
                      }}
                      disabled={isSubmited}
                    />
                    <FormField
                      type={formFields.repeatPassword.type}
                      label={formFields.repeatPassword.label}
                      name={formFields.repeatPassword.name}
                      value={values.repeatPassword}
                      error={
                        errors.repeatPassword &&
                        touched.repeatPassword
                      }
                      inputProps={{
                        type: "password",
                        autoComplete: "",
                      }}
                      disabled={isSubmited}
                    />
                  </SoftBox>
                  <SoftBox mt={5} mb={1}>
                    <SoftButton
                      type="submit"
                      color="primary"
                      fullWidth
                      disabled={isSubmited}
                    >
                      {t('auth.resetPasswordConfirm.button')}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Form>
            )}
          </Formik>
        </SoftBox>
      </Card>
      {ErrorMessageSnackBar({ title: t('auth.resetPasswordConfirm.errorMsg'), errorMessage, errorSB, closeErrorSB })}
      {SuccessMessageSnackBar({ title: t('auth.resetPasswordConfirm.success'), successMessage, successSB, closeSuccessSB })}
    </BasicLayout>
  );
}

export default ResetPasswordConfirm;
