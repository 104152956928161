const BackRequests = {
  // USER
  SIGN_IN: "user/login",
  SIGN_OUT: "user/logout",
  SIGN_UP: "user/add",
  EDIT_USER: "user/edit",
  EDIT_PASSWORD: "user/change/password",
  GET_USER: "user/", // user/:userId
  GET_USERS: "user/get",
  GET_USERS_BY_STATUS: "user/status/", // user/status/:status
  DEACTIVATE_USER_PROFILE: "user/deactivate/", // user/deactivate/:userId
  DELETE_USER_PROFILE: "user/", // user/:userId
  ACTIVATE_ACCOUNT: "user/activate/", // activate/:confirmationCode
  GET_USER_STATISTICS: "user/statistics",
  PASSWORD_RESET_INIT: "user/password/reset",
  PASSWORD_RESET_CONFIRM: "user/password/reset/confirm/", // password/reset/confirm/:userId/:token
  CHANGE_USER_STATUS: "user/status",

  // BUSINESS ITEM
  ADD_BUSINESS_ITEM: "business-item/add",
  EDIT_BUSINESS_ITEM: "business-item/edit",
  APPROVE_BUSINESS_REQUEST: "business-item/approve",
  REJECT_BUSINESS_REQUEST: "business-item/reject",
  GET_BUSINESS_REQUESTS: "business-item/status/pending-approval", // /status/:status
  GET_REJECTED_BUSINESS_REQUESTS: "business-item/status/rejected", // /status/:status
  GET_DELETED_BUSINESSES: "business-item/status/deleted", // /status/:status
  GET_ACTIVE_BUSINESSES: "business-item/status/active", // /status/:status
  GET_ACTIVE_BUSINESSES_BY_TYPE: "business-item/status/active/",  // /business-item/status/active/:type
  GET_BUSINESS_ITEM_BY_ID: "business-item/", // business-item/:id
  GET_BUSINESS_STATISTICS: "business-item/statistics",
  GET_BUSINESS_ITEMS_FOR_USER: "business-item/owner/", // business-item/owner/:ownerId
  GET_SPONSORED_BUSINESSES: "business-item/sponsored/", // business-item/sponsored/:type
  GET_SPONSORED_BUSINESSES_ALL_TYPES: "business-item/sponsored/all-types", 
  ADD_REVIEW: "business-item/review/add",
  GET_BUSINESS_ITEM_REVIEWS: "business-item/review/pagination",
  DELETE_BUSINESS_ITEM: "business-item/delete", 
  PROMOTE_BUSINESS_ITEM: "business-item/promote", 
  GET_BUSINESS_NAMES_BY_OWNER: "business-item/names/owner/", // business-item/names/owner/:ownerId
  SEARCH_BUSINESS_ITEMS: "business-item/search",
  GET_OWNER_FOR_BUSINESS_ITEM: "business-item/owner/data/", // business-item/owner/:businessItemId


  // MEDIA
  UPLOAD_MEDIA: "media/upload",
  GET_MEDIA: "media/", // media/:fileId
  
  // ENUMS
  GET_SPORTS: "enums/sports",
  GET_SKILL_LEVELS: "enums/skill-levels",
  GET_AGE_CATEGORIES: "enums/age-categories",
  GET_BUSINESS_ITEM_ADDITIONAL: "enums/additionals/", // enums/additionals/:businessItemType
  GET_ALL_ADDITIONAL: "enums/additionals/all",

  // BUSINESS ITEM RESERVATION
  CREATE_RESERVATION: "reservation/create",
  CLIENT_RESERVATIONS: "reservation/user/", // reservation/user/:userId
  CANCEL_RESERVATION: "reservation/cancel/", // reservation/cancel/:reservationId
  USE_RESERVATION: "reservation/use/", // reservation/use/:reservationId
  SEARCH_RESERVATIONS: "reservation/search"
};

export default BackRequests;
