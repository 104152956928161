/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import FormField from "components/common/FormField";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { CreatePostRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import BasicLayout from "../components/BasicLayout";
import runnerImg from "assets/images/auth-images/runner.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";

function ResetPasswordInit() {
  const { t, i18n } = useTranslation();

  // Error message
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  const initialValues = {
    ["email"]: "",
  };
  
  const validations = Yup.object().shape({
    ["email"]: Yup.string()
      .required(t('auth.resetPasswordInit.emailRequired'))
      .email(t('auth.resetPasswordInit.emailInvalid')),
  });

  const handleSubmit = async (values, actions) => {
    try {
      setIsSubmited(true)
      await CreatePostRequest(BackRequests.PASSWORD_RESET_INIT, {
        email: values.email,
      });
      localStorage.setItem('email', values.email);
      openSuccessSB(t('auth.resetPasswordInit.successMsg'));
    } catch (error) {
      openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <BasicLayout image={runnerImg}>
      <Card>
        <SoftBox p={3} pb={0} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {t('auth.resetPasswordInit.title')}
          </SoftTypography>
          <SoftTypography mt={3} variant="body2" color="text">
            {t('auth.resetPasswordInit.description')}
          </SoftTypography>
        </SoftBox>
        <SoftBox p={3}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id="reset-password-form-init" autoComplete="off">
                <SoftBox>
                  <SoftBox>
                    <FormField
                      type="email"
                      name="email"
                      label={t('auth.resetPasswordInit.email')}
                      value={values.email}
                      error={errors.email && touched.email}
                      disabled={isSubmited}
                    />
                  </SoftBox>
                  <SoftBox mt={5} mb={1}>
                    <SoftButton
                      type="submit"
                      color="primary"
                      fullWidth
                      disabled={isSubmited}
                    >
                      {t('auth.resetPasswordInit.button')}
                    </SoftButton>
                    <SoftBox mt={1.5} >
                      <SoftButton
                        component={Link}
                        color="light"
                        fullWidth
                        to={RoutesNames.SIGN_IN}
                        state={{ redirectToHome: true }}
                      >
                        {t('auth.resetPasswordInit.cancel')}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Form>
            )}
          </Formik>
        </SoftBox>
      </Card>
      {ErrorMessageSnackBar({ title: t('auth.resetPasswordInit.errorMsg'), errorMessage, errorSB, closeErrorSB })}
      {SuccessMessageSnackBar({ title: t('auth.resetPasswordInit.success'), successMessage, successSB, closeSuccessSB })}
    </BasicLayout>
  );
}

export default ResetPasswordInit;
