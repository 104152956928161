/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import RoutesNames from "helpers/enums/routes-map.enum";
import { Link } from "react-router-dom";

function SuccessStep({businessName, id}) {
  return (
    <SoftBox>
        <SoftTypography variant="h5" fontWeight="bold">
            Finish
        </SoftTypography>
        <SoftBox mt={2}>
            Congratulations! You have successfully created a new business: <b>{businessName}</b>
        </SoftBox>
        <SoftBox mt={2} textAlign="center">
            <SoftButton
                component={Link}
                to={RoutesNames.EVENT}
                state={{ id }}
                variant="gradient"
                color="success"
                size="medium"
                circular
            >
                Preview business
            </SoftButton>
        </SoftBox>
    </SoftBox>
  );
}

export default SuccessStep;
