/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All the routes for the Soft UI Dashboard PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the React router.
 9. The `href` key is used to store the external links' location.
 10. The `title` key is only for the item with the type of `title` and it's used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Soft UI Dashboard PRO React layouts
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import NewBusiness from "layouts/pages/business/new-business";
import CRM from "layouts/pages/admin/crm";
import UsersDataTable from "layouts/pages/admin/users/list-users";
import HomePage from "layouts/pages/home";
import AboutUs from "layouts/pages/home/about-us";

import ClientProfile from "layouts/pages/client/profile";
import EventPage from "layouts/pages/home/event";
import Document from "examples/Icons/Document";
import RoutesNames from "helpers/enums/routes-map.enum";
import AccountActivation from "layouts/authentication/activation";
import ResetPasswordInit from "layouts/authentication/reset-password-init";
import ResetPasswordConfirm from "layouts/authentication/reset-password-confirm";
import ActiveBusinessDataTable from "layouts/pages/admin/businesses/active-businesses";
import BusinessRequestsDataTable from "layouts/pages/admin/businesses/business-requests";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ClientReservations from "layouts/pages/client/reservations";
import EditBusiness from "layouts/pages/business/edit-business";
import BusinessProfileOverview from "layouts/pages/business/business-profile-overview";
import BusinessList from "layouts/pages/business/business-list";
import BusinessReservations from "layouts/pages/business/business-reservations";
import BusinessSearch from "layouts/pages/home/business-search";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import BusinessSettings from "layouts/pages/admin/businesses/business-settings";
import BusinessArchive from "layouts/pages/admin/businesses/archive";
import UserSettings from "layouts/pages/admin/users/user-settings";
import UserStatus from "helpers/enums/user-status.enum";


const routes = [
  // PUBLIC routes
  {
    type: "title",
    title: "Pages",
    key: "title-pages",
    route: RoutesNames.HOME,
    component: <HomePage/>,
  },
  {
    type: "title",
    title: "Pages",
    key: "event-pages",
    route: RoutesNames.EVENT, 
    component: <EventPage/>,
  },
  {
    type: "title",
    title: "Pages",
    key: "sport-facilities",
    route: RoutesNames.SPORT_FACILITIES, 
    component: <BusinessSearch type={BusinessItemTypes.SPORT_FACILITY}/>,
  },
  {
    type: "title",
    title: "Pages",
    key: "personal-coaches",
    route: RoutesNames.PERSONAL_COACHES, 
    component: <BusinessSearch type={BusinessItemTypes.PERSONAL_COACH}/>, 
  },
  {
    type: "title",
    title: "Pages",
    key: "group-activities",
    route: RoutesNames.GROUP_ACTIVITIES, 
    component: <BusinessSearch type={BusinessItemTypes.GROUP_ACTIVITY}/>,
  },
  {
    type: "title",
    title: "Pages",
    key: "event-activities",
    route: RoutesNames.EVENTS, 
    component: <BusinessSearch type={BusinessItemTypes.EVENT}/>,
  },
  {
    type: "title",
    title: "Pages",
    key: "search",
    route: RoutesNames.SEARCH, 
    component: <BusinessSearch allSearch/>,
  },
  {
    type: "title",
    title: "Pages",
    key: "about-us",
    route: RoutesNames.ABOUT_US, 
    component: <AboutUs/>,
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px"/>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        route: RoutesNames.SIGN_IN,
        component: <SignInBasic/>,
      },
      {
        name: "Sign Up",
        key: "sign-up",
        route: RoutesNames.SIGNUP,
        component: <SignUpBasic/>,
      },
      {
        name: "Activation", 
        key: "account-activation", 
        route: RoutesNames.ACCOUNT_ACTIVATION, 
        component: <AccountActivation/>,
      },
      {
        name: "Password reset init", 
        key: "password-reset-init", 
        route: RoutesNames.PASSWORD_RESET_INIT, 
        component: <ResetPasswordInit/>,
      },
      {
        name: "Password reset confirm", 
        key: "password-reset-confirm", 
        route: RoutesNames.PASSWORD_RESET_CONFIRM, 
        component: <ResetPasswordConfirm/>,
      },
    ],
  },
  // Business routes
  {
    type: "collapse",
    name: "Become business", // TODO
    key: "new-business",
    route: RoutesNames.NEW_BUSINESS,
    component: <NewBusiness/>,
    noCollapse: true,
    icon: <RocketLaunchIcon fontSize="small"></RocketLaunchIcon>
  },
  {
    type: "collapse",
    name: "Edit business",
    key: "edit-business",
    route: RoutesNames.EDIT_BUSINESS,
    component: <EditBusiness/>,
    noCollapse: true,
    icon: <RocketLaunchIcon fontSize="small"></RocketLaunchIcon>
  },
  {
    type: "collapse",
    name: "Business Profile Overview",
    key: "business-profile-overview",
    route:  RoutesNames.BUSINESS_DASHBOARD,
    component: <BusinessProfileOverview/>,
    noCollapse: true,
    icon: <Document size="12px"/>,
  },
  {
    type: "collapse",
    name: "Business List",
    key: "business-list",
    route:  RoutesNames.BUSINESS_LIST,
    component: <BusinessList/>,
    noCollapse: true,
    icon: <Document size="12px"/>,
  },
  {
    type: "collapse",
    name: "Business Reservations",
    key: "business-reservations",
    route:  RoutesNames.BUSINESS_RESERVATIONS,
    component: <BusinessReservations/>,
    noCollapse: true,
    icon: <Document size="12px"/>,
  },
  // Admin routes
  {
    type: "collapse",
    name: "Admin crm",
    key: "admin-crm",
    route: RoutesNames.ADMIN_CRM,
    component: <CRM/>,
    noCollapse: true,
    icon: <Document size="12px"/>,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users-table",
    icon: <Document size="12px"/>,
    collapse: [
      {
        name: "Active users",
        key: "active-users-table",
        route: RoutesNames.ADMIN_ACTIVE_USERS_TABLE,
        component: <UsersDataTable status={UserStatus.ACTIVE} key="active-user" />,
      },
      {
        name: "Blocked users",
        key: "blocked-users-table",
        route: RoutesNames.ADMIN_BLOCKED_USERS_TABLE,
        component: <UsersDataTable status={UserStatus.BLOCKED} key="blocked-user"/>,
      },
      {
        name: "Deleted users",
        key: "deleted-users-table",
        route: RoutesNames.ADMIN_DELETED_USERS_TABLE,
        component: <UsersDataTable status={UserStatus.DELETED} key="deleted-user"/>,
      },
    ]
  },
  {
    name: "User settings",
    key: "user-settings",
    route: RoutesNames.USER_SETTINGS,
    component: <UserSettings/>,
  },
  {
    type: "collapse",
    name: "Businesses",
    key: "businesses",
    icon: <Document size="12px"/>,
    collapse: [
      {
        name: "Active businesses",
        key: "active-businesses-table",
        route: RoutesNames.ACTIVE_BUSINESSES_TABLE,
        component: <ActiveBusinessDataTable/>,
      },
      {
        name: "Business requests",
        key: "business-requests-table",
        route: RoutesNames.BUSINESS_REQUESTS_TABLE,
        component: <BusinessRequestsDataTable/>,
      },
      {
        name: "Archive",
        key: "business-archive",
        route: RoutesNames.BUSINESSES_ARCHIVE,
        component: <BusinessArchive/>,
      }
    ]
  },
  {
    name: "Business settings",
    key: "business-settings",
    route: RoutesNames.BUSINESS_SETTINGS,
    component: <BusinessSettings/>,
  },
  {
    type: "title",
    name: "Active businesses by type",
    key: "active-businesses-table-by-type",
    route: RoutesNames.ADMIN_BUSINESS_TABLE_BY_TYPE,
    component: <ActiveBusinessDataTable/>,
  },
  // Client routes
  {
    type: "collapse",
    name: "Profile",
    key: "client-profile",
    route: RoutesNames.CLIENT_PROFILE,
    component: <ClientProfile/>,
    noCollapse: true,
    icon: <AccountBoxIcon fontSize="small"></AccountBoxIcon>
  },
  {
    type: "collapse",
    name: "Reservations",
    key: "client-reservations",
    route: RoutesNames.CLIENT_RESERVATIONS,
    component: <ClientReservations/>,
    noCollapse: true,
    icon: <SportsGymnasticsIcon fontSize="small"></SportsGymnasticsIcon>
  },
];

export default routes;
