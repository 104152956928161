/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";
import moment from 'moment'

// Helpers
import { CreateGetRequest } from "helpers/back-request/helpers"
import { SuccessMessageSnackBar, ErrorMessageSnackBar } from "helpers/messages/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HeaderMenu from "components/common/HeaderMenu";
import ReservationCard from "examples/Cards/ReservationCard";
import Sidenav from "./side-nav";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";
import { CreatePostRequest } from "helpers/back-request/helpers";
import loader from "assets/images/moveon/loader-simple.svg";

function ClientReservations() {
  const { t, i18n } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [initialValuesData, setInitialValuesData] = useState();
  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);
  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  }
  const closeSuccessSB = () => setSuccessSB(false);


  const getClientReservations = async () => {
    // Get client info from local storage
    const userLoginData = localStorage.getItem("user");
    const userLoginDataObj = JSON.parse(userLoginData);

    try {
      console.log("getClientReservations")
      // Get client info from database
      const response = await CreateGetRequest(BackRequests.CLIENT_RESERVATIONS + userLoginDataObj.userId);
      const userData = response.data.data;
      console.log(userData);

      for (let i = 0; i < userData.active.length; i++) {
        const activeReservationImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.active[i].businessItem.mainImage, { responseType: 'blob' });
        userData.active[i].businessItem.mainImageData = URL.createObjectURL(activeReservationImageResponse.data);
      }

      for (let i = 0; i < userData.canceled.length; i++) {
        const canceledReservationImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.canceled[i].businessItem.mainImage, { responseType: 'blob' });
        userData.canceled[i].businessItem.mainImageData = URL.createObjectURL(canceledReservationImageResponse.data);
      }

      for (let i = 0; i < userData.used.length; i++) {
        const usedReservationImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.used[i].businessItem.mainImage, { responseType: 'blob' });
        userData.used[i].businessItem.mainImageData = URL.createObjectURL(usedReservationImageResponse.data);
      }

      return userData;
    } catch (error) {
      console.log("error")
      console.log(error)
      //  return populateInitialValues(userLoginDataObj);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setShowLoader(true);
      const reservations = await getClientReservations();
      setInitialValuesData(reservations);
      setShowLoader(false);
    }
    loadData();
  }, []);

  const setResponseMessage = (message, isError) => {
    if (isError) {
      openErrorSB(message);
    } else {
      openSuccessSB(message);
    }
  };

  const updateInitialValues = async (reservationId) => {
    const newInitialValues = {
      active: initialValuesData.active.filter((item) => item.id != reservationId),
      used: initialValuesData.used,
      canceled: [...initialValuesData.canceled, initialValuesData.active.find((item) => item.id == reservationId)]
    }

    setInitialValuesData(newInitialValues)
  }

  return (
    <PageLayout>
      <SoftBox xs={12} md={12} lg={12} align="center">
        <HeaderMenu />
      </SoftBox>
      <SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
      })}>
        {!showLoader &&
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} id="active-reservations">
                    <Grid container spacing={3}>
                      <Grid item sm={12} lg={12}>
                        <SoftTypography color="primary">{t('reservations.activeReservations')}</SoftTypography>
                      </Grid>
                      {initialValuesData?.active.length > 0 ? initialValuesData?.active.map((reservation, key) => (
                        <Grid item sm={12} lg={3} width="100%" key={key}> <ReservationCard
                          id={reservation.id}
                          type={reservation.businessItem.type}
                          image={reservation.businessItem.mainImageData}
                          title={reservation.businessItem.name}
                          dateTime={moment(reservation.creationTime).format("DD/MM/yyyy HH:mm")}
                          reservation={reservation.chosenItem}
                          action={{ type: "clickAction", color: "secondary", label: t('reservations.card.cancel') }}
                          updateInitialValues={updateInitialValues}
                          actionCancel
                        /></Grid>
                      )) : (
                        <Grid item sm={12} lg={12}>
                          <SoftTypography variant="body2" color="text">{t('reservations.noActiveReservations')}</SoftTypography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} id="used-reservations">
                    <Grid container spacing={3}>
                      <Grid item sm={12} lg={12}>
                        <SoftTypography color="primary">{t('reservations.usedReservations')}</SoftTypography>
                      </Grid>
                      {initialValuesData?.used.length > 0 ? initialValuesData?.used.map((reservation, key) => (
                        <Grid item sm={12} lg={3} key={key}> <ReservationCard
                          id={reservation.id}
                          type={reservation.businessItem.type}
                          image={reservation.businessItem.mainImageData}
                          title={reservation.businessItem.name}
                          dateTime={moment(reservation.creationTime).format("DD/MM/yyyy HH:mm")}
                          reservation={reservation.chosenItem}
                          action={{ type: "label", route: "/", color: "primary", label: t('reservations.card.used') }}
                          disabled
                        /></Grid>
                      )) : (
                        <Grid item sm={12} lg={12}>
                          <SoftTypography variant="body2" color="text">{t('reservations.noUsedReservations')}</SoftTypography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} id="canceled-reservations">
                    <Grid container spacing={3}>
                      <Grid item sm={12} lg={12}>
                        <SoftTypography color="primary">{t('reservations.canceledReservations')}</SoftTypography>
                      </Grid>
                      {initialValuesData?.canceled.length > 0 ? initialValuesData?.canceled.map((reservation, key) => (
                        <Grid item sm={12} lg={3} key={key}> <ReservationCard
                          id={reservation.id}
                          type={reservation.businessItem.type}
                          image={reservation.businessItem.mainImageData}
                          title={reservation.businessItem.name}
                          dateTime={moment(reservation.creationTime).format("DD/MM/yyyy HH:mm")}
                          reservation={reservation.chosenItem}
                          action={{ type: "label", route: "/", color: "secondary", label: t('reservations.card.canceled') }}
                          disabled
                        /></Grid>
                      )) : (
                        <Grid item sm={12} lg={12}>
                          <SoftTypography variant="body2" color="text">{t('reservations.noCanceledReservations')}</SoftTypography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Grid>
        }
        {showLoader &&
          <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
            <img src={loader} alt="Loading..." className="loader" />
          </SoftBox>}
        {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
        {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default ClientReservations;