/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState} from "react";
import {Form, Formik} from "formik";
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import FormField from "components/common/FormField";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Separator from "layouts/authentication/components/Separator";

// Sign-in schemas for form and form fields
import useFormFields from "layouts/authentication/sign-in/basic/schemas/form";
import useInitialValues from "layouts/authentication/sign-in/basic/schemas/initialValues";
import useValidations from "layouts/authentication/sign-in/basic/schemas/validation";

// Images
import runnerImg from "assets/images/auth-images/runner.jpg";
import RoutesNames from "helpers/enums/routes-map.enum";
import UserRoles from "helpers/enums/user-role.enum";

// Helpers
import {ErrorMessageSnackBar} from "helpers/messages/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import {CreatePostRequest, GetErrorMessage} from "helpers/back-request/helpers"
import { useTranslation } from "react-i18next";

function Basic() {
  const { t, i18n } = useTranslation();
  const { formId, formField } = useFormFields(t);
  const initialValues = useInitialValues(t);
  const validations = useValidations(t)
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state;
  
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);

  const [rememberMe, setRememberMe] = useState(false);

  // TODO
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (values, { resetForm, setFieldValue }) => {
    try {
      const response = await CreatePostRequest(BackRequests.SIGN_IN,
        {email: values.email, password: values.password});

      localStorage.removeItem("email");
      localStorage.setItem('user', JSON.stringify(response.data.data));
      localStorage.setItem('userId',response.data.data.userId);
      redirectAfterSuccessLogin(response.data.data.role);
    } catch (error) {
      openErrorSB(GetErrorMessage(error));
      setFieldValue("password", "");
      const email = values.email;
      resetForm();
      setFieldValue("email", email);
    }
  };

  function redirectAfterSuccessLogin(role) {
    if (data?.redirectToHome) {
      navigate(RoutesNames.HOME);
      return;
    }
    switch (role) {
      case UserRoles.SYSTEM_ADMIN:
        navigate(RoutesNames.ADMIN_CRM);
        return;
      case UserRoles.BUSINESS_ADMIN:
        navigate(RoutesNames.BUSINESS_DASHBOARD);
        return;
      case UserRoles.END_CLIENT:
        navigate(-1);
        return;
    }
  }

  return (
    <BasicLayout
      title={t('auth.login.header')}
      description={t('auth.login.subheader')}
      image={runnerImg}
    >
      <Card>
        <SoftBox p={3} pb={0}  textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {t('auth.login.title')}
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox mb={2}>
          <Socials/>
        </SoftBox> */}
        <SoftBox p={3}>
          <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id={formId} autoComplete="off">
                    <SoftBox>
                      <SoftBox mb={2}>
                        <FormField
                          type={formField.email.type}
                          label={formField.email.label}
                          name={formField.email.name}
                          value={values.email}
                          placeholder={formField.email.placeholder}
                          error={errors.email && touched.email}
                        />
                      </SoftBox>
                      <SoftBox mb={2}>
                        <FormField
                          type={formField.password.type}
                          label={formField.password.label}
                          name={formField.password.name}
                          value={values.password}
                          placeholder={formField.password.placeholder}
                          error={errors.password && touched.password}
                        />
                      </SoftBox>
                      {/* <SoftBox display="flex" alignItems="center">
                        <Switch checked={rememberMe} onChange={handleSetRememberMe}/>
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          onClick={handleSetRememberMe}
                          sx={{cursor: "pointer", userSelect: "none"}}
                        >
                          &nbsp;&nbsp;Remember me
                        </SoftTypography>
                      </SoftBox> */}
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          color="secondary"
                          fullWidth
                          type="submit"
                        >
                         {t('auth.login.button')}
                        </SoftButton>
                      </SoftBox>
                      <Separator/>
                      <SoftBox mt={1} mb={3}>
                        <SoftButton
                          component={Link}
                          to={RoutesNames.SIGNUP}
                          color="primary"
                          fullWidth
                        >
                          {t('auth.signup.button')}
                        </SoftButton>
                      </SoftBox>
                      <SoftBox mt={3} textAlign="center">
                        <SoftTypography
                          variant="button"
                          color="text"
                          fontWeight="regular"
                        >
                          {t('auth.login.forgotPassword')}&nbsp;
                          <SoftTypography
                            component={Link}
                            to={RoutesNames.PASSWORD_RESET_INIT}
                            variant="button"
                            color="primary"
                            fontWeight="bold"
                          >
                            {t('auth.login.resetPassword')}
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Form>
                )}
            </Formik>
          </SoftBox>
      </Card>
      { ErrorMessageSnackBar({title: t('auth.login.errorMsg'), errorMessage, errorSB, closeErrorSB}) }
    </BasicLayout>
  );
}

export default Basic;