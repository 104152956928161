const FormNames = {
    CHOOSE_BUSINESS_TYPE: "choose-type-form",
    PROGRAM_DETAILS: "program-details",
    BUSINESS_INFO_FORM: "business-info-form",
    ADDRESS_FORM: "address-form",
    FILTER_FORM: "filter-form",
    SPORT_FACILITY_ADDITIONAL_FORM: "sport-facility-additional-form",
    MEDIA_FORM: "media-form",
    CONTACT_DATA_FORM: "contact-data-form",
    PRICING_FORM: "pricing-form",
    PREVIEW_DATA_FORM: "preview-data-form",
 }
 
 export default FormNames;