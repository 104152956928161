import useFormFields from "layouts/authentication/sign-in/basic/schemas/form";


const useInitialValues = (t) => {
    const {
        formField: {
            email,
            password,
        },
    } = useFormFields(t);

    const initialValues = {
        [email.name]: localStorage.getItem("email") ?? "",
        [password.name]: "",
    };

    return initialValues
};

export default useInitialValues;
