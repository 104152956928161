/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

import MediaQuery from 'react-responsive'
import Carousel from "react-multi-carousel";
// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "layouts/pages/home/components/Header";
import Footer from "layouts/pages/home/components/Footer";
import PersonalTrainerContent from "layouts/pages/home/components/PersonalTrainerContent";
import GroupActivitiesContent from "layouts/pages/home/components/GroupActivitiesContent";
import EventContent from "layouts/pages/home/components/EventContent";
import GymContent from "layouts/pages/home/components/GymContent";
import InfoContent from "layouts/pages/home/components/InfoContent";
import Faq from "layouts/pages/home/components/Faq";
import image1 from "assets/images/moveon/banner1.jpg";
import image2 from "assets/images/moveon/banner2.jpg";
import Moveon_home_process from "assets/images/moveon/Moveon_home_process.jpg";
import Moveon_home_process_vertical1 from "assets/images/moveon/Moveon_home_process_short1.jpg";
import Moveon_home_process_vertical2 from "assets/images/moveon/Moveon_home_process_short2.jpg";
import Moveon_home_process_vertical3 from "assets/images/moveon/Moveon_home_process_short3.jpg";
import Grid from "@mui/material/Grid";
import CounterContent from "./components/CounterContent";
import BlogContent from "./components/BlogContent";



import { Parallax, Background } from 'react-parallax';
import PublicHeader from "./components/PublicHeader";
import curved0 from "../../../assets/images/curved-images/curved2.jpg";

// Helpers
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import BusinessItemTypes from "helpers/enums/business-item.enum";
import UserRoles from "helpers/enums/user-role.enum";
import { useNavigate } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";


function HomePage() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);
  const navigate = useNavigate();

  const [sponsoredEvents, setSponsoredEvents] = useState([]);
  const [sponsoredSportFacilities, setSponsoredSportFacilities] = useState([]);
  const [sponsoredPersonalCoaches, setSponsoredPersonalCoaches] = useState([]);
  const [sponsoredGroupActivities, setSponsoredGroupActivities] = useState([]);

  const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 2048 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 2048, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

  const getSponsoredBusinesses = async () => {
    try {
      const sponsoredBusinesses = {};
      const responseEvents = await CreateGetRequest(BackRequests.GET_SPONSORED_BUSINESSES_ALL_TYPES);
      sponsoredBusinesses["sponsoredEvents"] = responseEvents.data.data;

      return responseEvents.data.data;
    } catch (error) {
      /*console.log("error")
      console.log(error)
      // TODO: Sta na stranici prikazati ako se ne ucitaju podaci
      openErrorSB(setErrorMessage(error), true);*/
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    console.log(userData)
    if (userData?.role === UserRoles.SYSTEM_ADMIN) {
      navigate(RoutesNames.ADMIN_CRM);
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const sponsoredBusinesses = await getSponsoredBusinesses();

      if (sponsoredBusinesses) {
        // Get media
        for (let i = 0; i < sponsoredBusinesses.sponsoredEvents.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredEvents[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredEvents[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredEvents(sponsoredBusinesses.sponsoredEvents);

        for (let i = 0; i < sponsoredBusinesses.sponsoredSportFacilities.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredSportFacilities[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredSportFacilities[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredSportFacilities(sponsoredBusinesses.sponsoredSportFacilities);

        for (let i = 0; i < sponsoredBusinesses.sponsoredPersonalCoaches.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredPersonalCoaches[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredPersonalCoaches[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredPersonalCoaches(sponsoredBusinesses.sponsoredPersonalCoaches);

        for (let i = 0; i < sponsoredBusinesses.sponsoredGroupActivities.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredGroupActivities[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredGroupActivities[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredGroupActivities(sponsoredBusinesses.sponsoredGroupActivities);
      }
    }
    loadData();
  }, []);


  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <PageLayout>
      <SoftBox align="center">
        <PublicHeader />
      </SoftBox>
      <SoftBox align="center">
        <InfoContent />
        <GroupActivitiesContent sponsoredGroupActivities={sponsoredGroupActivities} />
        <MediaQuery maxWidth={430}>
          <SoftBox position="relative"
            zIndex={0} mt={4}
            px={{ xs: 1, sm: 0 }}
            width="100%"
            align="justify">
            <SoftBox px={1.5}>
                <Carousel responsive={responsive} infinite={true} autoPlay={false}>
                    <SoftBox align="center">
                        <SoftBox width="90%" height="230px"
                        sx={{
                              backgroundImage: `url(${Moveon_home_process_vertical1})`,
                              backgroundSize: "cover"
                            }}>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox align="center">
                        <SoftBox width="90%" height="230px"
                        sx={{
                              backgroundImage: `url(${Moveon_home_process_vertical2})`,
                              backgroundSize: "cover"
                            }}>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox align="center">
                        <SoftBox width="90%" height="230px"
                        sx={{
                              backgroundImage: `url(${Moveon_home_process_vertical3})`,
                              backgroundSize: "cover"
                            }}>
                        </SoftBox>
                    </SoftBox>
                </Carousel>
            </SoftBox>
        </SoftBox>
        </MediaQuery>
        <PersonalTrainerContent sponsoredPersonalCoaches={sponsoredPersonalCoaches} />
        <MediaQuery minWidth={431}>
          <SoftBox width="90%"
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="22.75rem"
            marginTop="40px"
            sx={{
              backgroundImage: `url(${Moveon_home_process})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",

            }}
          />
        </MediaQuery>
        <GymContent sponsoredSportFacilities={sponsoredSportFacilities} />
        <SoftBox padding="50px 0 10px 0">
          <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={image2}
            strength={-200}
          >
            <div style={{ height: '300px' }} >
              <Container>
                {// <CounterContent />
                }
              </Container>
            </div>
          </Parallax>
        </SoftBox>
        <EventContent sponsoredEvents={sponsoredEvents} />
      </SoftBox>
      <Container>
        <Faq />
      </Container>
      <SoftBox mt={8}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default HomePage;
