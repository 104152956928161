import useFormFields from "layouts/pages/home/schemas/form";
import FormNames from "layouts/pages/home/schemas/form-names.enum";
import {useTranslation} from "react-i18next";

const useInitialValues = () => {
    const {t} = useTranslation(); // Call useTranslation inside a hook or component
    const loggedInUser = localStorage.getItem("user");
    let loggedInUserData;
    if (loggedInUser) {
        loggedInUserData = JSON.parse(loggedInUser);
    }

    const {
        [FormNames.SEARCH]: {
            businessName,
            location,
            category,
            searchGender,
            searchAdditional,
            searchSkillLevel,
            searchAgeCategory,
            searchSport,
            reviewScore,
        },
        [FormNames.SIMPLE_SEARCH]: {
            businessNameSimpleSearch,
            locationSimpleSearch,
            categorySimpleSearch,
        },
        [FormNames.MAKE_RESERVATION]: {
            firstName,
            lastName,
            email,
            service,
        },
    } = useFormFields(t);

    const initialClearData = {
        [FormNames.SEARCH]: {
            [businessName.name]: "",
            [location.name]: "",
            [category.name]: null,
            [searchGender.name]: searchGender.default,
            [searchAdditional.name]: [],
            [searchSkillLevel.name]: [],
            [searchAgeCategory.name]: [],
            [searchSport.name]: [],
            [reviewScore.name]: 0,
        },
        [FormNames.SIMPLE_SEARCH]: {
            [businessNameSimpleSearch.name]: "",
            [locationSimpleSearch.name]: "",
            [categorySimpleSearch.name]: null,
        },
        [FormNames.MAKE_RESERVATION]: {
            [firstName.name]: loggedInUserData?.firstName ?? "",
            [lastName.name]: loggedInUserData?.lastName ?? "",
            [email.name]: loggedInUserData?.email ?? "",
            [service.name]: [],
        },
    };

    const initial = {
        ...initialClearData,
    };

    const getInitialValuesForSearch = () => {
        return {
            [businessName.name]: "",
            [location.name]: "",
            [category.name]: null,
            [searchGender.name]: searchGender.default,
            [searchAdditional.name]: [],
            [searchSkillLevel.name]: [],
            [searchAgeCategory.name]: [],
            [searchSport.name]: [],
            [reviewScore.name]: 0,
        };
    };

    return {
        initial,
        getInitialValuesForSearch,
    };
};

export default useInitialValues;
