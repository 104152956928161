import * as Yup from "yup";
import useFormFields from "layouts/authentication/sign-in/basic/schemas/form";

const useValidations = (t) => {

    const {
        formField: { email, password },
    } = useFormFields(t);

    return Yup.object().shape({
            [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
            [password.name]: Yup.string().required(password.errorMsg).min(5, password.invalidMsg),
            // TODO: What is the rule for the password
        });
};

export default useValidations;
