const useFormFields = (t) => {
    return {
        formId: "sign-up-form",
        formField: {
            firstName: {
                name: "firstName",
                label: t('auth.signup.firstName'),
                type: "text",
                errorMsg: t('auth.signup.firstNameRequired'),
            },
            lastName: {
                name: "lastName",
                label: t('auth.signup.lastName'),
                type: "text",
                errorMsg: t('auth.signup.lastNameRequired'),
            },
            email: {
                name: "email",
                label: t('auth.signup.email'),
                type: "email",
                errorMsg:  t('auth.signup.emailRequired'),
                invalidMsg: t('auth.signup.emailInvalid'),
            },
            password: {
                name: "password",
                label: t('auth.signup.password'),
                type: "password",
                errorMsg: t('auth.signup.passwordRequired'),
                invalidMsg: t('auth.signup.passwordInvalid'), /* TODO */
            },
            repeatPassword: {
                name: "repeatPassword",
                label: t('auth.signup.repeatPassword'),
                type: "password",
                errorMsg: t('auth.signup.repeatPasswordRequired'),
                invalidMsg: t('auth.signup.repeatPasswordInvalid'),
            },
        },
    };
}
export default useFormFields;
