import useFormFields from "layouts/authentication/sign-up/basic/schemas/form";

const useInitialValues = (t) => {
    const {
        formField: {
            firstName,
            lastName,
            email,
            password,
            repeatPassword,
        },
    } = useFormFields(t);

    return {
        [firstName.name]: "",
        [lastName.name]: "",
        [email.name]: "",
        [password.name]: "",
        [repeatPassword.name]: "",
    };
};

export default useInitialValues;
